import React from "react";
import "./styles.scss";
import Slider from "react-slick";
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useEffect } from "react";
import { OfferBanner } from '../../components';
import { changeTab } from "../../actions/general";
import { useTranslation } from "react-i18next";
import { Helmet } from "react-helmet";

export default function About() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const data = [
    { logo: 'about-brand-1.png' },
    { logo: 'about-brand-2.png' },
    { logo: 'about-brand-3.png' },
    { logo: 'about-brand-4.png' },
    { logo: 'about-brand-5.png' },
    { logo: 'about-brand-6.png' },
    { logo: 'about-brand-7.png' },
    { logo: 'about-brand-8.png' },
    { logo: 'about-brand-9.png' },
    { logo: 'about-brand-10.png' },
    { logo: 'about-brand-11.png' },
    { logo: 'about-brand-12.png' },
    { logo: 'about-brand-13.png' },
  ]

  const settings = {
    dots: false,
    arrows: true,
    // prevArrow: <img className='slick-prev' src={require('../../assets/icons/arrow-button-left.svg')} />,
    // nextArrow: <img className='slick-next' src={require('../../assets/icons/arrow-button-right.svg')} />,
    infinite: true,
    // infinite: false,
    speed: 500,
    slidesToShow: 6,
    slidesToScroll: 2,
  };

  useEffect(() => {
    window.scrollTo(0, 0)
    dispatch(changeTab('about'))
  }, [dispatch]);

  return (
    <div className="screen-wrapper about-screen">

      <Helmet>
        <title>Synergon Energy About</title>
        <meta name="robots" content="index, follow"></meta>
      </Helmet>
      <div className="screen-inner-wrapper">
        <div className="main-section top">
          <div className="section-wrapper left">
            <div className="title-wrapper">
              <h1>{t('general.headerAbout')}</h1>
              <div className="flex">
                <div className="line" />
                <h2>{t('home.aboutSectionSubtitle')}</h2>
              </div>
            </div>

            <div className="text-wrapper">
              <p>{t('about.aboutTextOne')}</p>
              <p>{t('about.aboutTextTwo')}</p>
              <p>{t('about.aboutTextThree')}</p>
            </div>
          </div>

          <div className="section-wrapper right">
            <div className="text-wrapper">
              <p>{t('about.aboutGraphicOver')}</p>
              <h2 className="text-title">13 000</h2>
              <p>{t('about.aboutGraphicClients')}</p>
            </div>

            <div className="graphic-wrapper">
              <img src={require('../../assets/images/about-screen-years.png')} alt='timeline' />
            </div>

            <div className="text-wrapper">
              <h2 className="text-title">2000</h2>
              <p>{t('productsAndServices.manufacturers')}</p>
            </div>
          </div>

          <div className="benefits-container">
            <div>
              <div className="icon-container">
                <img className='icon' src={require('../../assets/icons/experience.svg').default} alt='experience' />
              </div>
              <div className="text-container">
                <p>{t('about.aboutBenefitsTitleOne')}</p>
                <span>{t('about.aboutBenefitsTextOne')}</span>
              </div>
            </div>
            <div>
              <div className="icon-container">
                <img className='icon' src={require('../../assets/icons/electricity-traded.svg').default} alt='electricity-traded' />
              </div>
              <div className="text-container">
                <p>{t('about.aboutBenefitsTitleTwo')}</p>
                <span>{t('about.aboutBenefitsTextTwo')}</span>
              </div>
            </div>
            <div>
              <div className="icon-container">
                <img className='icon' src={require('../../assets/icons/energy-vei.svg').default} alt='energy-vei' />
              </div>
              <div className="text-container">
                <p>{t('about.aboutBenefitsTitleThree')}</p>
                <span>{t('about.aboutBenefitsTextThree')}</span>
              </div>
            </div>
            <div>
              <div className="icon-container">
                <img className='icon' src={require('../../assets/icons/power-vei.svg').default} alt='power-vei' />
              </div>
              <div className="text-container">
                <p>{t('about.aboutBenefitsTitleFour')}</p>
                <span>{t('about.aboutBenefitsTextFour')}</span>
              </div>
            </div>
            <div>
              <div className="icon-container">
                <img className='icon' src={require('../../assets/icons/team.svg').default} alt='team' />
              </div>
              <div className="text-container">
                <p>{t('about.aboutBenefitsTitleFive')}</p>
                <span>{t('about.aboutBenefitsTextFive')}</span>
              </div>
            </div>
          </div>
        </div>

        <div className="main-section activities">
          <div className="title-wrapper">
            <h2 className="title-section">{t('about.aboutActivityAndPolicyTitle')}</h2>
            <div className="flex">
              <div className="line" />
              <h2>{t('about.aboutActivityAndPolicySubtitle')}</h2>
            </div>
          </div>

          <div className="cards-wrapper">
            <div className='section-wrapper card'>
              <img className='icon' src={require('../../assets/icons/case.svg').default} alt='case' />
              <h2>{t('about.aboutActivity')}</h2>
              <p>{t('about.aboutActivityText')}</p>
            </div>

            <div className='section-wrapper card'>
              <img className='icon' src={require('../../assets/icons/shield.svg').default} alt='shield' />
              <h2>{t('about.aboutPolicy')}</h2>
              <p>{t('about.aboutPolicyText')}</p>
            </div>
          </div>

          <div className="cards-wrapper">
            <div className='section-wrapper card benefits'>
              <img className='icon' src={require('../../assets/icons/benefits.svg').default} alt='benefits' />
              <h2>{t('about.aboutBenefits')}</h2>
              <div className="benefits-container">
                <p>{t('about.aboutBenefitOne')}</p>
                <p>{t('about.aboutBenefitTwo')}</p>
                <p>{t('about.aboutBenefitThree')}</p>
                <p>{t('about.aboutBenefitFour')}</p>
                <p>{t('about.aboutBenefitFive')}</p>
                <p>{t('about.aboutBenefitSix')}</p>
              </div>
            </div>
          </div>

          <div className="team-wrapper">
            <div className="title-wrapper">
              <h2 className="title-section">{t('about.aboutTeam')}</h2>
              <div className="flex">
                <div className="line" />
                <h2 className="desktop">{t('about.aboutTeamSubtitle')}</h2>
                {/* <h2 className="mobile">Кои сме ние</h2> */}
              </div>
            </div>

            <div className='cards-wrapper'>
              <div className='section-wrapper video-card'>
                <iframe className='card-image' width="560" height="315" controls="0" src="https://www.youtube.com/embed/9Ra2YxjhGkI" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture;" allowFullScreen></iframe>

                <div className='card-text-wrapper'>
                  <h2>{t('about.aboutTeamPersonOne')}</h2>
                  <p>{t('about.aboutTeamPersonOneDescription')}</p>
                </div>
              </div>

              <div className='section-wrapper video-card'>
                {/* <img className='card-image' src={require('../../assets/images/about-video-2.png')} /> */}
                <iframe className='card-image' width="560" height="315" src="https://www.youtube.com/embed/BuQwXtqx16o" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture;" allowFullScreen></iframe>

                <div className='card-text-wrapper'>
                  <h2>{t('about.aboutTeamPersonTwo')}</h2>
                  <p>{t('about.aboutTeamPersonTwoDescription')}</p>
                </div>
              </div>

              <div className='section-wrapper video-card'>
                {/* <img className='card-image' src={require('../../assets/images/about-video-3.png')} /> */}
                <iframe className='card-image' width="560" height="315" src="https://www.youtube.com/embed/1utlngzK09c" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture;" allowFullScreen></iframe>

                <div className='card-text-wrapper'>
                  <h2>{t('about.aboutTeamPersonThree')}</h2>
                  <p>{t('about.aboutTeamPersonThreeDescription')}</p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="main-section brands">
          <div className="title-wrapper">
            <h2 className="title-section">{t('about.aboutSynergonHolding')}</h2>
            <div className="flex">
              <div className="line" />
              <h2>{t('about.aboutSynergonHoldingSubtitle')}</h2>
            </div>
            <p className="text-wrapper">{t('about.aboutSynergonHoldingText')}</p>
          </div>

          <div className='cards-wrapper desktop'>
            <Slider {...settings}>
              {data?.map((el, index) => {
                return (
                  <div className='card-wrapper' key={`logo-${index}`}>
                    {el.logo && <img src={require(`../../assets/images/about-brand-${index + 1}.png`)} alt='brand' />}
                  </div>
                )
              })}
            </Slider>
          </div>

          <div className='cards-wrapper mobile'>
            {data?.map((el, index) => {
              return (
                <div className='card-wrapper' key={`logo-${index}`}>
                  {el.logo && <img src={require(`../../assets/images/about-brand-${index + 1}.png`)} alt='brand' />}
                </div>
              )
            })}
          </div>

        </div>

        <div className="main-section bottom">
          <div className='row products'>
            <div className='section-wrapper card'>
              <img className='icon' src={require('../../assets/icons/electricity.svg').default} alt='electricity' />
              <h2>{t('general.headerProductsAndServices')}</h2>
              <p>{t('productsAndServices.usersSubtitle')}</p>
              <div className='link' onClick={() => navigate('/products-and-services')}>
                <span>{t('general.learnMore')}</span>
                <img src={require('../../assets/icons/arrow-button-right.svg').default} alt='arrow right' />
              </div>
            </div>
          </div>

          <div className='row services'>
            <div className='section-wrapper card end margin'>
              <img className='icon' src={require('../../assets/icons/partners_icon.svg').default} alt='partners' />
              <h2>{t('general.headerPartners')}</h2>
              <p>{t('about.aboutPartnersText')}</p>
              <div className='link' onClick={() => {
                navigate('/partners')
                dispatch(changeTab('partners'))
              }}>
                <span>{t('general.learnMore')}</span>
                <img src={require('../../assets/icons/arrow-button-right.svg').default} alt='arrow right' />
              </div>
            </div>
          </div>
        </div>

        <OfferBanner />
      </div>
    </div>
  );
}
