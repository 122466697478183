import { Header, Footer } from './components';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { changeLanguage } from './actions/general';
import { useTranslation } from "react-i18next";
import { Home, Products, Users, Manufacturers, Services, About, Partners, Contact, Info, Article, Resources, OffersInitial, OffersOptions, OfferManufacturer, OfferUser } from './screens';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { Helmet } from 'react-helmet';
import "./App.scss";
import ScrollToTop from './components/ScrollToTop/ScrollToTop';

function App() {
  const dispatch = useDispatch();
  const { i18n } = useTranslation();

  useEffect(() => {
    localStorage.setItem('code', 'bg')
    dispatch(changeLanguage("bg"));
    i18n.changeLanguage("bg");

    return localStorage.setItem('code', 'bg')
  }, [dispatch, i18n])

  const { pathname } = window.location
  const screen = pathname.split('/')[1]
  return (
    <div className={`screen ${screen}`}>
      <BrowserRouter>
        <Header />
        <ScrollToTop />
        <Helmet>
          <title>Synergon Energy</title>
          <meta name="viewport" content="width=device-width, initial-scale=1" />
          <meta name="theme-color" content="#000000" />
          <meta name="description" content="Synergon Energy е българският доставчик на ток, който Ви изготвя персонална оферта, грижи се вместо Вас за прехвърлянето и Ви спестява пари." />
        </Helmet>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/about" element={<About />} />
          <Route path="/partners" element={<Partners />} />
          <Route path="/products-and-services" element={<Products />} />
          <Route path="/users" element={<Users />} />
          <Route path="/manufacturers" element={<Manufacturers />} />
          <Route path="/services" element={<Services />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/info" element={<Info />} />
          <Route path="/article/:id" element={<Article />} />
          <Route path="/resources" element={<Resources />} />
          <Route path="/offers-initial" element={<OffersInitial />} />
          <Route path="/offers-options" element={<OffersOptions />} />
          <Route path="/offer-manufacturer" element={<OfferManufacturer />} />
          <Route path="/offer-user" element={<OfferUser />} />
        </Routes>
        <Footer />
      </BrowserRouter>
    </div>
  );
}

export default App;
