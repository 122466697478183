import { createSlice } from "@reduxjs/toolkit";
import { httpClient } from "../utilities/httpClient";

const initialState = {
  contacts: {},
};

export const contact = createSlice({
  name: "Contact",
  initialState,
  reducers: {
    getContactSuccess: (state, { payload }) => {
      state.contacts = payload;
    },
  },
});

export const { getContactSuccess } = contact.actions;

export const getContacts =
  ({ payload }) =>
  async (dispatch) => {
    const response = await httpClient.post(`/contact/list`);

    dispatch(getContactSuccess(response?.data?.contacts));
    if (payload.onSuccess) payload.onSuccess(response?.data?.contacts);
  };
