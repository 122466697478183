import React from 'react';
import './styles.scss';
import { useNavigate } from 'react-router-dom';
import { useState, useEffect } from 'react';
import { OfferBanner } from '../../components';
import { useTranslation } from "react-i18next";

export default function Services() {
    const navigate = useNavigate();
    const [ hoveredCard, setHoveredCard ] = useState('');
    const { t } = useTranslation();

    useEffect(() => {
      window.scrollTo(0, 0, { behavour: 'smooth' })
    }, []);

  return (
    <div className='screen-wrapper services'>
      <div className='screen-inner-wrapper'>
        <div className='screen-header-wrapper'>
              <div className='flex padding' onClick={() => navigate(-1)}>
                  <img src={require('../../assets/icons/arrow.svg').default} alt='arrow back' /> 
                  <span>{t('general.back')}</span>
              </div>
  
              <div className='section-wrapper text'>
                  <div className='title-wrapper'>
                    <h1>{t('productsAndServices.servicesTitle')}</h1>
                    <div className='flex'>
                      <div className='line orange' />
                      <h2>{t('services.servicesSubtitle')}</h2>
                    </div>
                    <p>{t('services.servicesText')}</p>
                  </div>
             </div>
          </div>

          <div className='screen-main-wrapper desktop'>
            <div className='cards-wrapper'>

               <div className={`section-wrapper card ${hoveredCard === 'first' ? 'hidden' : ''}`}
                    onMouseOver={() => setHoveredCard('first')}  onMouseLeave={() => setHoveredCard('')}>
                      <div className='section-inner-wrapper'>
                       <img className='icon' src={require('../../assets/icons/services-icon-1.svg').default} alt='services' />
                       <h2>{t('services.cardOneTitle')}</h2>
                       <p>{t('services.cardOneText')}</p>
                      </div>
        
                       <div className={`flex col ${hoveredCard === 'first' ? 'hovered' : 'hidden'}`}>
                         <div className='flex start'>
                            <div className='line orange' />
                            <h5>{t('services.cardOneHoveredTextOne')}</h5>
                          </div>
                         <div className='flex start'>
                            <div className='line orange' />
                            <h5>{t('services.cardOneHoveredTextTwo')}</h5>
                          </div>
                         <div className='flex start'>
                            <div className='line orange' />
                            <h5>{t('services.cardOneHoveredTextThree')}</h5>
                          </div>
        
                          <button className="primary-bttn" onClick={() => navigate('/contact')}>{t('general.contactUs')}</button>
                      </div>
                </div>

               <div className={`section-wrapper card ${hoveredCard === 'second' ? 'hidden' : ''}`}
                    onMouseOver={() => setHoveredCard('second')}  onMouseLeave={() => setHoveredCard('')}>
                      <div className='section-inner-wrapper'>
                       <img className='icon' src={require('../../assets/icons/services-icon-2.svg').default} alt=''/>    
                       <h2>{t('services.cardTwoTitle')}</h2>
                       <p>{t('services.cardTwoText')}</p>
                      </div>
        
                       <div className={`flex col ${hoveredCard === 'second' ? 'hovered' : 'hidden'}`}>
                         <div className='flex start'>
                            <div className='line orange' />
                            <h5>{t('services.cardTwoHoveredTextOne')}</h5>
                          </div>
                         <div className='flex start'>
                            <div className='line orange' />
                            <h5>{t('services.cardTwoHoveredTextTwo')}</h5>
                          </div>
                         <div className='flex start'>
                            <div className='line orange' />
                            <h5>{t('services.cardTwoHoveredTextThree')}</h5>
                          </div>
        
                          <button className="primary-bttn" onClick={() => navigate('/contact')}>{t('general.contactUs')}</button>
                      </div>
                </div>

               <div className={`section-wrapper card ${hoveredCard === 'third' ? 'hidden' : ''}`}
                    onMouseOver={() => setHoveredCard('third')}  onMouseLeave={() => setHoveredCard('')}>
                      <div className='section-inner-wrapper'>
                       <img className='icon' src={require('../../assets/icons/services-icon-3.svg').default} alt='services' />
                       <h2>{t('services.cardThreeTitle')}</h2>
                       <p>{t('services.cardThreeText')}</p>
                      </div>
        
                       <div className={`flex col ${hoveredCard === 'third' ? 'hovered' : 'hidden'}`}>
                         <div className='flex start'>
                            <div className='line orange' />
                            <h5>{t('services.cardThreeHoveredTextOne')}</h5>
                          </div>
                         <div className='flex start'>
                            <div className='line orange' />
                            <h5>{t('services.cardThreeHoveredTextTwo')}</h5>
                          </div>
                         <div className='flex start'>
                            <div className='line orange' />
                            <h5>{t('services.cardThreeHoveredTextThree')}</h5>
                          </div>
        
                          <button className="primary-bttn" onClick={() => navigate('/contact')}>{t('general.contactUs')}</button>
                      </div>
                </div>
            </div>
          </div>

          <div className='screen-main-wrapper mobile'>
            <div className='cards-wrapper'>
                <div className='section-wrapper card'>
                      <div className='section-inner-wrapper'>
                         <img className='icon' src={require('../../assets/icons/services-icon-1.svg').default} alt='services' />
                         <h2>{t('services.cardOneTitle')}</h2>
                         <p>{t('services.cardOneText')}</p>
                      </div>
        
                       <div className='flex col'>
                         <div className='flex start'>
                            <div className='line orange' />
                            <h5>{t('services.cardOneHoveredTextOne')}</h5>
                          </div>
                         <div className='flex start'>
                            <div className='line orange' />
                            <h5>{t('services.cardOneHoveredTextTwo')}</h5>
                          </div>
                         <div className='flex start'>
                            <div className='line orange' />
                            <h5>{t('services.cardOneHoveredTextThree')}</h5>
                          </div>
                      </div>
                </div>

                <div className='section-wrapper card'>
                  <div className='section-inner-wrapper'>
                    <img className='icon' src={require('../../assets/icons/services-icon-2.svg').default} alt='services' />    
                    <h2>{t('services.cardTwoTitle')}</h2>
                    <p>{t('services.cardTwoText')}</p>
                  </div>
    
                   <div className='flex col'>
                     <div className='flex start'>
                        <div className='line orange' />
                        <h5>{t('services.cardTwoHoveredTextOne')}</h5>
                      </div>
                     <div className='flex start'>
                        <div className='line orange' />
                        <h5>{t('services.cardTwoHoveredTextTwo')}</h5>
                      </div>
                     <div className='flex start'>
                        <div className='line orange' />
                        <h5>{t('services.cardTwoHoveredTextThree')}</h5>
                      </div>
                  </div>
                </div>

                <div className='section-wrapper card'>
                      <div className='section-inner-wrapper'>
                       <img className='icon' src={require('../../assets/icons/services-icon-3.svg').default} alt='services' />
                       <h2>{t('services.cardThreeTitle')}</h2>
                       <p>{t('services.cardThreeText')}</p>
                      </div>
        
                       <div className='flex col'>
                          <div className='flex start'>
                            <div className='line orange' />
                            <h5>{t('services.cardThreeHoveredTextOne')}</h5>
                          </div>
                          <div className='flex start'>
                            <div className='line orange' />
                            <h5>{t('services.cardThreeHoveredTextTwo')}</h5>
                          </div>
                          <div className='flex start'>
                            <div className='line orange' />
                            <h5>{t('services.cardThreeHoveredTextThree')}</h5>
                          </div>
                      </div>
                </div>
            </div>

            <button className="primary-bttn" onClick={() => navigate('/contact')}>{t('general.contactUs')}</button>
          </div>

          <OfferBanner />
      </div>
    </div>
  )
}
