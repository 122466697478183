import { createSlice } from "@reduxjs/toolkit"
import {httpClient} from '../utilities/httpClient'
// import {  startLoading, stopLoading } from "actions"

const initialState = {
  usersList: {},
  singleUser: {}
}

export const ResourcesSlice = createSlice({
  name: "Resources",
  initialState,
  reducers: {
    createFileSuccess: (state, { payload }) => {
      state.articleList = payload
    },
    getFileSuccess: (state, { payload }) => {
      state.article = payload
    },
    editFileSuccess: (state, { payload }) => {
      state.article = payload
    },
    deleteFileSuccess: (state, { payload }) => {
      state.article = payload
    }
  },
})

export const {
    createFileSuccess,
    getFileSuccess,
    editFileSuccess,
    deleteFileSuccess
} = ResourcesSlice.actions

export const getFilesList = ({payload, onSuccess}) => async () => {
  const response = await httpClient.post("/file/browse", payload)

  if(onSuccess) onSuccess(response?.data?.files)
}

export const createFile = ({data, onSuccess}) => async (dispatch) => {
  const response = await httpClient.post("/file", data)
  
  dispatch(createFileSuccess(response?.data?.payload))
  if(onSuccess) onSuccess(response?.data?.file)
}

export const getFile = ({id, onSuccess}) => async (dispatch) => {
  const response = await httpClient.get(`/file/${id}`)
  
  dispatch(getFileSuccess(response))
  if(onSuccess) onSuccess(response?.data?.article)
}

export const editFile = ({id, data, onSuccess}) => async (dispatch) => {
  const response = await httpClient.put(`/file`, data)
  
  dispatch(editFileSuccess(response))
  if(onSuccess) onSuccess(response)

}

export const deleteFile = ({data, onSuccess}) => async (dispatch) => {
  const response = await httpClient.put(`/file`, data)
  
  dispatch(editFileSuccess(response))
  if(onSuccess) onSuccess(response?.data?.article)

}