import React, { useEffect, useMemo } from 'react';
import './styles.scss';
import { useDispatch, useSelector } from 'react-redux';
import { getFilesList } from '../../actions/resources';
import { useTranslation } from 'react-i18next';

export default function Footer() {
    const dispatch = useDispatch();
    const [documents, setDocuments] = React.useState([]);
    const language = useSelector(state => state.general.language)
    const { t } = useTranslation()
    // const files = useSelector(state => state.resources.filesFooter)
    
  const titleIndexMap = useMemo(() => ({
    'Закон за енергетиката': 0,
      'Правила за търговия с електрическа енергия': 1,
        'Политика за защита на личните данни': 2,
          'Общи условия за ползване': 3
  }),[]);
      
      useEffect(() => {
        dispatch(getFilesList({
          onSuccess: (data) => {
            const filteredDocuments = data.filter((el) => el.footerTitle in titleIndexMap).sort((a, b) => titleIndexMap[a.footerTitle] - titleIndexMap[b.footerTitle]);
            setDocuments(filteredDocuments); 
          }
        }));
      }, [dispatch, language, titleIndexMap]);

    return (
    <div className='footer-wrapper'>
      <div className='footer-inner-wrapper'>
        <div className={`row top ${documents?.length ? 'border' : ''}`}>
            <div className='item border'>
                <img src={require('../.././assets/icons/logo-white.svg').default} className='logo' alt='logo' />
            </div>

            <div className='item border'>
                <img src={require('../.././assets/icons/phone.svg').default} className='icon' alt='phone' />
                <div className='flex-col'>
                    <h5>{t('general.phoneNumber')}</h5>
                    <h5>0700 800 80</h5>
                </div>
            </div>

            <div className='item border'>
                <img src={require('../.././assets/icons/email.svg').default} className='icon' alt='email' />
                <div className='flex-col'>
                    <h5>{t('general.email')}</h5>
                    <h5 className='light-text'>office@synergonenergy.bg</h5>
                </div>
            </div>
            <div className='item'>
                <img src={require('../.././assets/icons/location.svg').default} className='icon' alt='location' />
                <div className='flex-col'>
                    <h5>{t('general.address')}</h5>
                    <h5 className='light-text'>{t('general.synergonEnergyAddress')}</h5>
                </div>
            </div>
        </div>

        <div className={`row bottom ${!documents?.length ? 'hidden' : ''}`}>
            {documents?.map((el, index) => {
                return (
                    <a href={el.url} target='_blank' rel="noreferrer" className='footerResource' key={`${el?.footerTitle}-${index}`}> <h5 className='large'>{language === 'bg' ? el?.footerTitle : el?.footerTitleEnglish}</h5></a>
                )
            }
            )}
        </div>
      </div>
    </div>
  )
}
