import React, { useState, useEffect } from 'react';
import './styles.scss';
import { OffersTopSection, OfferInvoice } from '../../../components';
import { setOfferFillType } from '../../../actions/general';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';

export default function OfferUser() {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const selectedType = useSelector(state => state.general.offerFillType);

  const [payload, setPayload] = useState({
    fullName: '',
    email: '',
    phoneNumber: '',
    firmName: '',
    uic: '',
    invoice: [],
    address: '',
    averageConsumation: '',
    comment: '',
    policyAgreement: false
  })

  const handleChange = (newPayload) => setPayload((payload) => ({ ...payload, ...newPayload }));

  useEffect(() => {
    setPayload({
      fullName: '',
      email: '',
      phoneNumber: '',
      firmName: '',
      uic: '',
      invoice: [],
      address: '',
      averageConsumation: '',
      comment: '',
      policyAgreement: false
    })
  }, [selectedType])

  useEffect(() => {
    window.scrollTo(0, 0, { behavour: 'smooth' })
  }, []);


  return (
    <div className='screen-wrapper offer user'>
      <div className='screen-inner-wrapper'>
        <OffersTopSection />

        <div className='form-wrapper'>
          <div className='section-wrapper'>
            <div className='section-title'>
              <div className='flex start col'>
                <h2>{t('offers.getOfferType')}</h2>
                <p>{t('offers.getOfferTypeSubtitle')}</p>
              </div>
            </div>

            <div className='section-content'>
              <div className={`card ${selectedType === 'invoice' ? 'selected' : ''}`} onClick={() => dispatch(setOfferFillType('invoice'))}>
                <h2>{t('offers.uploadInvoice')}</h2>
                <img className='icon' src={require('../../../assets/icons/upload.svg').default} alt='upload' />
              </div>

              <div className={`card ${selectedType === 'manual' ? 'selected' : ''}`} onClick={() => dispatch(setOfferFillType('manual'))}>
                <h2>{t('offers.insertData')}</h2>
                <img className='icon' src={require('../../../assets/icons/pen.svg').default} alt='pen' />
              </div>
            </div>
          </div>

          {selectedType === 'invoice' && <OfferInvoice payload={payload} onChange={handleChange} type='invoice' />}
          {selectedType === 'manual' && <OfferInvoice payload={payload} onChange={handleChange} type='manual' />}

        </div>
      </div>
    </div>
  )
}

