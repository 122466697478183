export const mapAttributes = [
  {
    name: "Сливен",
    props: {
      d: "M675.4 274.8l-3.5 6.3 3.2 7.4 0.5 4.3-1.5 5 2.3 5.8 1.9 5.6-2.4 3.2-0.7 4 0.8 5.4-3.6 3.3-5 1.9-4 3.6 2.2 5.6 5.1 1.7 3.8 2.5 3.9 2 5.5-1.6 5.3-2.8 0.5 7.2-1.6 6.4-1.7 2.5-2.1 1.2-4.2-0.7-1.6 2-2.2 1.6-2.5 2.3-2.5 3.2-9.2 2.9-9.2-0.8-4.6 1.1-4.2 3 1.7 3.5 1.7 2.9-3.1 1.6-2.7 2.5-3.3 8.6-7.4 2.5-8.1-3-3.3 2.1 1.6 6.7-4.7 3.2-5.8 0.5 1.1 7.9 5 0.3 2.9 2.1-8.2 19.8 1.2 10.5-2.3 7.9-5.8 5.1-3.8 0.7-3.5-1.6-4.6 0.2-4.9 1.6-8.1-9.7 0.8-15.8-3.2-9.8-7.4-10.3-2.1-4.9 0.2-5.4-4.5-8.2 1.9-4.3-0.4-5.2-2.7-5.2-3.6-1.4-1.8-3.2 8.3-6.4-0.7-7.7-4.5-8.7-4.6-15.4-1.7-14.8 7.2-0.2 7.2-1.6 10.5 1.5 3.6-2.6 3.3-3.7 3.7-0.9 3.7 0.1 2-4.9-0.4-6.3 2.5-3.8 3.8-1.2 6.9-4 3.6-8.6 4.2-0.6 4.1-1.5 2-3.1 2.3-2.8 2.7-1.2 1-2.9 0-6.5 2.8 0.6 2.6-0.1 4.4-2 4 1.8-0.7 2.6 1.1 2.4 1.6-1.1 1.2-2.1 6 4.1 4.1 0.2 3.9-0.9 3.6-2.3 3.5 1.4 4.7 3.3 5 2.6z",
      id: "BGR2231",
      name: "Сливен",
    },
  },
  {
    name: "Хасково",
    props: {
      d: "M606.6 478.5l13.7 6.9 14.6 1.9 4.8 3.7 1.2 6.7 1.4 3.3 2.4-3.8 3.4-4.6 5.5 1.3 3.8 5.3-0.2 8.1 3.2 3.8 4 2.6 4.1 2 3.5-2-0.8 5.5-1.3 3.9-2.2 2.9-7.6 2.4-16.4-0.7-6.6 5.8-2.2 5.1-0.6 4.7 1.1 4.2 1.6 2.3-6.1 0.6-3.4-1-2-1.7-4.3-4.9-1.3-0.8-2.3-0.2-3.5 3.4-8.7 0.3-4.3 1.1-4.3 3.5-3.3 0.9-1.1 1.3-0.9 2.6-0.1 3.1 0.5 3.1 1.4 3.5-1.1-0.6-6.8 2.3-1.7 3.6-3-0.1-2.6 1.5-1.1 4.4-6.4 6.2-7-3.3-7.3-7.8-1-4.2-0.6-4.3-5.6-2.1-5.6 2.7-1.9 4.2-3.3 2.4-2.8-0.5-2.7-1.5-3.4 1.5-3.1 0.6-5.4-2.8 1-5.9 3.6-8.5-4.2-8.5-1.7-2.7-1.8-1.7-3 0-2.7-1.8-3-3.6-3.5 0.1-2.7 3.1-3.4 0.3-3.5-3-6.5-2.9-1.5-3 0.2-4.2-1.8-3.3-3 0.2-2.7 1.6-3.6 4.5-4.9 1.6-8.4-1.5-5.8-13.2 1.9-5.4 8.1-5.5 5.4-9.2 0.5-3.4-1.8-1.9-0.8-1.9 0.9-2.4-1.2-7.7-1.6-7.4 0.3-9.9 3.9 0.4 3.3 1.5 5.7 2.4 5.3 0 3 2.5 5.2 0.6 1.5-7.6 2.3-7 5.3-2.1 5.4 1.9 7.7-5.4 5.4 2.7 0.2 3.7-0.3 3.7-2.3 3.4 3.9 1.1 5-2.4 4.5 3.8 6.5 6.9 3.8 5.3 7.5-3.6 6.6-6.5 1.8-2.6 2.2-1.4 1.4 0.8 11.3 3.3 8.4 9.2 4.1 6 4.6 3.8 6.7-3.5 7-2.1z",
      id: "BGR2232",
      name: "Хасково",
    },
  },
  {
    name: "Стара Загора",
    props: {
      d: "M553.7 319.1l1.7 14.8 4.6 15.4 4.5 8.7 0.7 7.7-8.3 6.4 1.8 3.2 3.6 1.4 2.7 5.2 0.4 5.2-1.9 4.3 4.5 8.2-0.2 5.4 2.1 4.9 7.4 10.3 3.2 9.8-0.8 15.8 8.1 9.7 4.9-1.6 4.6-0.2 3.5 1.6 3.8-0.7-0.1 12.1 2.2 5.8-0.1 6-7 2.1-6.7 3.5-4.6-3.8-4.1-6-8.4-9.2-11.3-3.3-1.4-0.8-2.2 1.4-1.8 2.6-6.6 6.5-7.5 3.6-3.8-5.3-6.5-6.9-4.5-3.8-5 2.4-3.9-1.1 2.3-3.4 0.3-3.7-0.2-3.7-5.4-2.7-7.7 5.4-5.4-1.9-5.3 2.1-2.3 7-1.5 7.6-5.2-0.6-3-2.5-5.3 0-5.7-2.4-4-5.9-5.9-4.1-5.1-1.3-5.5 1.5-3 0.3-2.3 5.3-2.9-0.6 0.6-3-1.8-1.4-2.5-1.1-1.7-2-1.3 0.8-3.1-2.2-2.4-8.8 2.1-7.4 5.4-2.2 4.6-2.8-3.5-7.1-1.8-4.8-0.2-5.3-0.9-7.6 4.4-3.2 6 2.5 2.7 0.4 1-3.1-0.8-2.8-2-1-3.7-4.2 3.1-7.7-2.6-4.4-5.2 1.7-7.2-0.1-6.2-4.7-2.7-3.7-1.2-4.8 0-2.9-3.8-8.7 3-1.1 1.5-4.3-2.6-15.5 0.8-7.6 8.2 0.6 8.2-1.6 8.3-3.9 8-0.4 2.3 3.7 2.6 2.8 6.4-3.9 8.7 1.7 8.3 3.7 5.1-0.5 3.9-3 5.3 1.4 2.9-1.3 2.4-1.6 3 1.2 3.8-4.3 2.6 1.2 2.7 3.8 3.2 2.6 2.3-2.3-1.2-4 3.8 0.3 2.8 1.8 5.4-4.1 5.8-2.8 2.9 1 3 0.4 3.4-0.5 3.4 0.4z",
      id: "BGR2233",
      name: "Стара Загора",
    },
  },
  {
    name: "Пазарджик",
    props: {
      d: "M330 367.1l-1.8 10.3-0.4 10.9 3.5 0.7 1.9 5.5-0.3 2.1 0.2 4.9 1.5 4.8 0 4.1 2.9 1.2 3.7 6.4-2.4 5.7 0.2 3.1 2.6 3.8 1.2 5.9-0.7 6.1-0.2 4.4 2.7 2 2.5 3-2.3 3.3-4.4 1.1-3.7 2.9 0.5 9-1.9 4.8 0.9 5.9 2.5 10.2-1.9 10-1.7 6-2.6 6.8-5.9 3.1-4.6 2.9-6.8 9.5-0.1 9.9-6.9 6.2-9.1 0.3-3.1 2.9-2.8-0.2 1.2-3.8 0.7-3.9-6.3-5.8-8-1.3-5.8 1.1 0.5 5.2 2.9 2.3 2.4 3.2 0.4 7-4.7 4.9-3-1.4-1.9-3.7-18.1-12.5-1.7-3.4-2-2.5-3.1 0.8-2.8-0.6-3.5-3.6-3.9-2.5-4.2-7.9-0.9-9.7-2.5-3.4-1.9-3.9 0.9-3.5-0.2-4.3 4-9.5 4.6-5.3-3.1-3.7 0-4.8 1.9-2.5 0.5-3.4-1-4.4-2.8-3-3.1-2.5-1.6-4.2 2.8-0.7 2.4-1.7-0.2-2.8 1.1-2 7.5-3.1 5.2-7.7 1.2-6.4 3.9-1.1 1.8 2.8 2.1 2.1 3.3 1.1 2.6-3.2-0.1-3.6-2.6-2.1-2.5-8.1 2.8-9.8 6.4-0.7 6.2-2.7 2.6-8-2.5-4.3-8.1 1.3-6-5.4-2-3-2.6-2.1-2.2-4-1.5-4.6 0.9-4.8 4.7-1.7 4.4-0.5 4.3 1.6 3.5 3 3.9 1.1 7.5-4.3 4 0 3.7-1.9 4.4-6.3 5.9-2.4 2.7-0.5 2.8-1.2 4.6 0.3 4.3 1.5 1.8 3.4 0.9 3.8 2.9 1.4 3 0.9 1.9 2.7 2.4 0.1 3.4-0.6 3.4 1.2z",
      id: "BGR2234",
      name: "Пазарджик",
    },
  },
  {
    name: "Пловдив",
    props: {
      d: "M426.2 324.4l0 2.3-0.8 7.6 2.6 15.5-1.5 4.3-3 1.1 3.8 8.7 0 2.9 1.2 4.8 2.7 3.7 6.2 4.7 7.2 0.1 5.2-1.7 2.6 4.4-3.1 7.7 3.7 4.2 2 1 0.8 2.8-1 3.1-2.7-0.4-6-2.5-4.4 3.2 0.9 7.6 0.2 5.3 1.8 4.8 3.5 7.1-4.6 2.8-5.4 2.2-2.1 7.4 2.4 8.8 3.1 2.2 1.3-0.8 1.7 2 2.5 1.1 1.8 1.4-0.6 3 2.9 0.6 2.3-5.3 3-0.3 5.5-1.5 5.1 1.3 5.9 4.1 4 5.9-3.3-1.5-3.9-0.4-0.3 9.9 1.6 7.4 1.2 7.7-0.9 2.4 0.8 1.9 1.8 1.9-0.5 3.4-5.4 9.2-8.1 5.5-4.3-1.1-3.6 2.4-1.5 2.5-2.3 0.9-2.5 3.3-1.5 4.7-3.6 2.9-0.8 4.7-0.9 3.3-3.8-0.3-2.7-1.2-3-0.1-6-8.1-4.3 1.4-5.3 0.2-3.1-5.7-3.9-3.6-7.1-4.5-5.8-7.6-7.8-2.1-7.8 1-8.3 2.4-7.8 5-7.6 3.6-17.8-13.8 1.9-10-2.5-10.2-0.9-5.9 1.9-4.8-0.5-9 3.7-2.9 4.4-1.1 2.3-3.3-2.5-3-2.7-2 0.2-4.4 0.7-6.1-1.2-5.9-2.6-3.8-0.2-3.1 2.4-5.7-3.7-6.4-2.9-1.2 0-4.1-1.5-4.8-0.2-4.9 0.3-2.1-1.9-5.5-3.5-0.7 0.4-10.9 1.8-10.3 5.4-0.3 4.2-3 0.7-4.8-4.1-2.9-4.9-7-6.9-3.6-2.1-10 5.9-10.3 6.5 0.7 9.9-8.8 6.1-0.1 7.5 0.9 7.3 2.9 5.3 7.1 6.4 5.6 7.6 1.6 2.4-0.5 1.3-1.6 8.4 0 8.1 1.9 7.4-0.8 6.6-3.9 0.5-2.7 1.7-1.9 5-1.2z",
      id: "BGR2235",
      name: "Пловдив",
    },
  },
  {
    name: "Смолян",
    props: {
      d: "M435.1 532.3l-2.8 3.1-1.8 4.2 1.7 3.7 2.7 3.9 1.8 7.1 2.2 2.3-1.1 4.2 0.3 4.9 1.2 2-0.5 1.6-4.1 3-4.4 1.1-3.6-0.7-3.4 1.3 0.1 4.8 2.8 3.9 2.6 6.5 3.1 6 3.8 1.9 2.8-4.4 8.5-2.4 1.8 9.8-1 2.7 0.8 2-0.6 5.3-2.1 4.7 3.2 5.2-1.4 3.9 1.4 10.1-0.2 2.3-5.9-2.9-2.4-3.3-3.4 0.5-26.1-11.1-4.8-3-2.2-0.3-4.9 1.5-6.2 0.5-0.5 2.8 0.4 3.7-1.3 3.1-3.2-0.2-3.5-3.1-5.4-7-3.1-2.8-2.9-1.4-3.1-0.5-3.7 0 1-2.1-5.6 0.1-2.2-0.5-2.4-2.3 0.2-3.2-0.7-1.7-1.6-1-2.9-3.7-0.4-1.7-0.5-6-2-5.5-3.3-3-4.6 1.8-3.6 0.7-3.2 4.7-2.4 0.5-3.3-0.5-2.5 0.2-5.4 1.6-1.4 0.2-4.2-0.5-3.6 1.1-1.5-0.5 0-4.8-3.1-2-2.6-2.9-2.8 0.3-3 1.3-2.7 1.7-19.1-16.3 4.1-2.7 3.1-3.8-4.2-2.9-1.5-2.7-2.3-1.2 4.7-4.9-0.4-7-2.4-3.2-2.9-2.3-0.5-5.2 5.8-1.1 8 1.3 6.3 5.8-0.7 3.9-1.2 3.8 2.8 0.2 3.1-2.9 9.1-0.3 6.9-6.2 0.1-9.9 6.8-9.5 4.6-2.9 5.9-3.1 2.6-6.8 1.7-6 17.8 13.8 7.6-3.6 7.8-5 8.3-2.4 7.8-1 7.8 2.1 5.8 7.6 7.1 4.5 3.9 3.6 3.1 5.7 5.3-0.2 4.3-1.4 6 8.1 3 0.1 2.7 1.2z",
      id: "BGR2236",
      name: "Смолян",
    },
  },
  {
    name: "Кърджали",
    props: {
      d: "M459.9 509l-1.9 5.4 5.8 13.2 8.4 1.5 4.9-1.6 3.6-4.5 2.7-1.6 3-0.2 1.8 3.3-0.2 4.2 1.5 3 6.5 2.9 3.5 3 3.4-0.3 2.7-3.1 3.5-0.1 3 3.6 2.7 1.8 3 0 1.8 1.7 1.7 2.7 4.2 8.5-3.6 8.5-1 5.9 5.4 2.8 3.1-0.6 3.4-1.5 2.7 1.5 2.8 0.5 3.3-2.4 1.9-4.2 5.6-2.7 5.6 2.1 0.6 4.3 1 4.2 7.3 7.8 7 3.3 6.4-6.2 1.1-4.4 2.6-1.5 3 0.1 1.7-3.6 6.8-2.3 1.1 0.6 1.2 1.8 2.3 1.1 4 1.3 2.3 3.4 1.5 5.5-0.1 4.9 1.1 2.2 2.6 0.5 0.8 1 0.8 2.8 0.3 2.4-0.9 5.1-1.8 1.9-0.3 1.5 1.6 3.7 2.9 3.1 0.2 3-1.8 0.9-0.8 1.3-1.8 5.3-2.4 5.3-1.9 3-2.2 0.2-13.6 3.2-2 1-4.3 2.9-3.6 1.8-1.8 0.2-4.3-0.5-4 2.1-2.3 0.5-3.1-1.3-4.6-5.2-3.6-1.4-1.6 0.7-6 4-5.5 0.4-1.8 0.7-4.2 3.3-3.3-0.2-3.3-1.9-1.6-0.6-14.1-1-2.1 0.8-2.3 4.3-1.4 1.8-1.6 0.6-4.1-0.7-4.1 0.7-26.8 8.7-3.7 0.3-3.7-0.6-3.1-1.9-6.8-9.3-3.7-2.9 0.2-2.3-1.4-10.1 1.4-3.9-3.2-5.2 2.1-4.7 0.6-5.3-0.8-2 1-2.7-1.8-9.8-8.5 2.4-2.8 4.4-3.8-1.9-3.1-6-2.6-6.5-2.8-3.9-0.1-4.8 3.4-1.3 3.6 0.7 4.4-1.1 4.1-3 0.5-1.6-1.2-2-0.3-4.9 1.1-4.2-2.2-2.3-1.8-7.1-2.7-3.9-1.7-3.7 1.8-4.2 2.8-3.1 3.8 0.3 0.9-3.3 0.8-4.7 3.6-2.9 1.5-4.7 2.5-3.3 2.3-0.9 1.5-2.5 3.6-2.4 4.3 1.1z",
      id: "BGR2237",
      name: "Кърджали",
    },
  },
  {
    name: "София",
    props: {
      d: "M149.9 373.5l-3-3.4-9.4-7.2-5-2-0.7-7.6-1.1-4.1-1.7-3.6-3.3-1.7-2.8-2.3-3.8-3.8-4.2-2.8 5-6.3 6.7-0.7 3.3 2.3 3.8 0.6 2.8-1.6 2.4-2.9 0.4-4.3-2-3.9 7.8-6.5 0.2-10 3.8-7.6 2.5 1 2 2.4 8 5.3 7.7 2.2 8-3.4 7.3-1.6 6.2 5.7 6 0.4 5.7-4-2 5.7-3.3 6.2-8.6 19-3 13.3 2.4 4.7 6.8 16.5 8.4 14.9 5 4.8 1.6 7.2-4 4.1-4.5-2-6.7 1.1-4.3-2.9 1.8-7.1-3.2-6-1.1-3.7 0-2.6-6.5-1.4-3 6.3-4 6.2-5.7-1.4-5.7-4.5-13-7z",
      id: "BGR2243",
      name: "София",
    },
  },
  {
    name: "София (област)",
    props: {
      d: "M169.1 236.2l5.1 0.2 4.9 2.6 1.3 2.9-3.4 0.1-2.3 1.8 0.1 4.4 2.4 2.3 2.7 1.9 4.3 4 3.7 4.9 2.4 4.9 3.3 3.6 6.2-3.8 6.8-5.7 2.5 2.3 2.4 3.1 4.2 0.2 4-1.6 3.5-4.4 4.4-2.9 6 2.6 15.7 2.9 2.6 1.3 2.7 2 3.8-2.6 2.9-4.4 4.6-0.1 2.9 3.2 1.2 3.2 2.2 2.3 1 4.5-1.2 4.6 3.4 4 3.9 3.9 6.2 1.4 3.6 5.9 0.4 7.1-0.3 7.1-2.2 8.7 3.2 5 20.2 0.2 3.8 2.2 3.7 2.6 5.1 1.1 5.2-0.5-5.9 10.3 2.1 10 6.9 3.6 4.9 7 4.1 2.9-0.7 4.8-4.2 3-5.4 0.3-3.4-1.2-3.4 0.6-2.4-0.1-1.9-2.7-3-0.9-2.9-1.4-0.9-3.8-1.8-3.4-4.3-1.5-4.6-0.3-2.8 1.2-2.7 0.5-5.9 2.4-4.4 6.3-3.7 1.9-4 0-7.5 4.3-3.9-1.1-3.5-3-4.3-1.6-4.4 0.5-4.7 1.7-0.9 4.8 1.5 4.6 2.2 4 2.6 2.1 2 3 6 5.4 8.1-1.3 2.5 4.3-2.6 8-6.2 2.7-6.4 0.7-2.8 9.8 2.5 8.1 2.6 2.1 0.1 3.6-2.6 3.2-3.3-1.1-2.1-2.1-1.8-2.8-3.9 1.1-1.2 6.4-5.2 7.7-7.5 3.1-1.1 2 0.2 2.8-2.4 1.7-2.8 0.7-2.5-0.1-1.8 2.4-2.9 1.4-3.1-1.4-5.4 1-4.3 1.7-5.9 0.8-3.1 7.8-5.3 6.5-8.6-3.2 4-3.1 1.7-4.6-4.2-2.1-4.8-0.3-3.1-5.6-4.1-3.2-7 2-7.2 1-2.3-4.9 5-6.6 2-3.9 3.2-1.1 1.7-2.7-2.3-4.5-3.5-1.3-2.6-2.9-1.2-2.1-1.8-1.1-5.4-12.1-3.4 0.8-3.7 0.2-2.6-1.4-2.8 0.4 0.7-3.9 2.1-3.2-1.7-9.4 3.6-9.7 5.4-8.2 13 7 5.7 4.5 5.7 1.4 4-6.2 3-6.3 6.5 1.4 0 2.6 1.1 3.7 3.2 6-1.8 7.1 4.3 2.9 6.7-1.1 4.5 2 4-4.1-1.6-7.2-5-4.8-8.4-14.9-6.8-16.5-2.4-4.7 3-13.3 8.6-19 3.3-6.2 2-5.7-5.7 4-6-0.4-6.2-5.7-7.3 1.6-8 3.4-7.7-2.2-8-5.3-2-2.4-2.5-1-3.8 7.6-0.2 10-7.8 6.5 2 3.9-0.4 4.3-2.4 2.9-2.8 1.6-3.8-0.6-3.3-2.3-6.7 0.7-5 6.3-2.4-6.5-3.1-5-4.8 0.7-3-0.7-1.7-2.9-4.9 0-2.7-5.4-3.1-3.8-3.9 2.4-3.2-2.2 1-6.9-2.3-1.3-2.2 3.9-2.6 0.5-1.2-1.1-1.5 0.2-1.4-2-0.9-2.7-2.7-4.3-4.4-4 1-2.5 2.8-10.5 1-2.8 1.1-1.8 1.9-1.2 4.4-1 2-0.8 2.2-3.1 6.7-6.4 0.8-1.7 1.2-4 2.1-2.7 4-2.3 3.3-4.9 2.9-7.2 1.7-7.3-0.3-2.8 7.4-1.7 11.5 13.8 4.1 3.2 14.9 1.3 5.5-2.5 5-1.4 5.1 1.8 7.1-2.8 5.8-6.4z",
      id: "BGR2244",
      name: "София (област)",
    },
  },
  {
    name: "Перник",
    props: {
      d: "M114.9 335l4.2 2.8 3.8 3.8 2.8 2.3 3.3 1.7 1.7 3.6 1.1 4.1 0.7 7.6 5 2 9.4 7.2 3 3.4-5.4 8.2-3.6 9.7 1.7 9.4-2.1 3.2-0.7 3.9-4.4 0.6-4.2-2.3-4.8-0.8-4.6-4-2 5.9-2.7 0.4-3-2-8.6-2.9-3.6 1-9.6 0.8-6.4 5.3-4.4 0.8-3.7-2.3-2.7-4.6-3.5-3.5-3.6-2.2-2.5-4.1-3.3-4.1-2.5-3.8 0.5-6.9-2.8-4.2-4.7 1.4-2.7-3.2-0.6-4.5-2.8-2.9-3-5.2-2.1-6.2-4.9-2.6-6 0.6-7.8-1.2-6.4 0.5 1.2-7.6-1.1-3 4.1-7.9 2.2-2.1 0-2.6-2.4-1.9-2.2-3.3-3.8-9.2-0.3-1.7 1.5-2.3 1.4-1.3 4.1-2.1 1.7-1.5 2.4-3.8 1.5-1.3 5 0.4 2-1.9 2.2-1.5 4.3-0.6 12 3.3 4.9-1.2 4.8-2.1 2-2.6 4.4 4 2.7 4.3 0.9 2.7 1.4 2 1.5-0.2 1.2 1.1 2.6-0.5 2.2-3.9 2.3 1.3-1 6.9 3.2 2.2 3.9-2.4 3.1 3.8 2.7 5.4 4.9 0 1.7 2.9 3 0.7 4.8-0.7 3.1 5 2.4 6.5z",
      id: "BGR2245",
      name: "Перник",
    },
  },
  {
    name: "Габрово",
    props: {
      d: "M435.9 232.3l1 4.2 3.5 2.5 2.7 5.6 4.2 3.3 21.6 0.4 2.3 4.6 2.1 5.8 4.1 1.9 10.4 1.2 11.1-0.4 5 1.5 9.7 1.7 2.7 8.5-3.7 12 6.8 6.9 3.2 0.9 1.9 3.2-0.1 5.7-1.4 5.1-3.4 6.8 3.6 8.9 1.2 4-2.3 2.3-3.2-2.6-2.7-3.8-2.6-1.2-3.8 4.3-3-1.2-2.4 1.6-2.9 1.3-5.3-1.4-3.9 3-5.1 0.5-8.3-3.7-8.7-1.7-6.4 3.9-2.6-2.8-2.3-3.7-8 0.4-8.3 3.9-8.2 1.6-8.2-0.6 0-2.3-1.4-10.6-4-10-6.3-4.4-6.9-3.3-1.5-2.4-1-2.8 0.6-3.3-0.7-3.2-1.8-9.7 8.4-10.2-1.3-7.1 2-8.9 6-4.4 1.1-8.3 2.6-6.8 7.5 1.1 6.4 2.2z",
      id: "BGR2246",
      name: "Габрово",
    },
  },
  {
    name: "Ловеч",
    props: {
      d: "M442.2 210.6l0.8 14.2-7.1 7.5-6.4-2.2-7.5-1.1-2.6 6.8-1.1 8.3-6 4.4-2 8.9 1.3 7.1-8.4 10.2 1.8 9.7 0.7 3.2-0.6 3.3 1 2.8 1.5 2.4 6.9 3.3 6.3 4.4 4 10 1.4 10.6-5 1.2-1.7 1.9-0.5 2.7-6.6 3.9-7.4 0.8-8.1-1.9-8.4 0-1.3 1.6-2.4 0.5-7.6-1.6-6.4-5.6-5.3-7.1-7.3-2.9-7.5-0.9-6.1 0.1-9.9 8.8-6.5-0.7-5.2 0.5-5.1-1.1-3.7-2.6-3.8-2.2-20.2-0.2-3.2-5 2.2-8.7 0.3-7.1-0.4-7.1-3.6-5.9-6.2-1.4-3.9-3.9-3.4-4 1.2-4.6-1-4.5-2.2-2.3-1.2-3.2-2.9-3.2-4.6 0.1-2.2-5.8-0.8-6.2 2.4-1.7 2.8 2.5 4 0 1.7-4.9 1.3-8.7-1.3-8.5 8.5-5 8.4-3.9 3.8 3.2 3.6-0.8 4.9 2.7 4 0.7 3.9-1.2 4.7-1.9 4.5-1.2 2.7-3.2 3.1-3 3.1-0.9 3.1 0 2.9 3.1 2.1 4.2 3.1 3 11.5 3.4 7.8 0.3 1.6 2.9 2.5-1 5.6-4.8 3-3.8 1.3-3.1 1.8-2.5 1.7 2.5 1.8-1.3 2.3-3.4 8.5-6.2 9.7-0.6 3.7 0.3 6-0.7 3.4 4.1 4.2-0.2 4.4-1.3 4.8-2.1 4.2-3.1 2.9-2.8 0.8-5.7 3.2 4.4 4.1 8.3 5.8 5.7z",
      id: "BGR2247",
      name: "Ловеч",
    },
  },
  {
    name: "Плевен",
    props: {
      d: "M469.9 120.3l-0.5 1.3-4.3 2.7-3.9 3.6-2.2 8.9-4.7 5.8 1.2 6.9 2.6 0.4 1.3 2.1-3.7 7.7-4.6 5.9-5 5.1 1.8 4.7 6.8-0.4 1.4 7.9 3.4 7 0.9 8.3-6.7 6.4-2.9 1.9-2.6 2.5-6 1.6-5.8-5.7-4.1-8.3-3.2-4.4-0.8 5.7-2.9 2.8-4.2 3.1-4.8 2.1-4.4 1.3-4.2 0.2-3.4-4.1-6 0.7-3.7-0.3-9.7 0.6-8.5 6.2-2.3 3.4-1.8 1.3-1.7-2.5-1.8 2.5-1.3 3.1-3 3.8-5.6 4.8-2.5 1-1.6-2.9-7.8-0.3-11.5-3.4-3.1-3-2.1-4.2-2.9-3.1-3.1 0-3.1 0.9-3.1 3-2.7 3.2-4.5 1.2-4.7 1.9-3.9 1.2-4-0.7-4.9-2.7-3.6 0.8-3.8-3.2-8.4 3.9-8.5 5-10.8-4.3-1.4-3 1.7-4.4 2.8-6.4 11.8-4.7 4.7 1.7 1.7-2.3 3.1-2 5.4-0.3 5.5 0.9 7.6-3.9 1.3-12.8 1.3-4.2-0.2-3.8-2.9-10.3-7.4-1.5-4.3-6.9-1.8-7.9-3.2-6.7 3.9-3.8 4.4-5.3 4.3-3.8 5.1 0.5 9-4.4 6.6-10.2 1.2-10.6 3.4-0.2 2.8-0.8 8.9-6.7 5.6-1.8 5.4 0.6 32.7 12.3 7.5 1.1 33.7-2.4 18.8 6.8 20.7 1.6 6.5 1.6 5.3 3.1z",
      id: "BGR2248",
      name: "Плевен",
    },
  },
  {
    name: "Велико Търново",
    props: {
      d: "M501.3 124.9l1.2 2.5 2.9 9.7-3.4 7.6-3.5 2.7 0.8 4.8 8.2 6.2 0.2 2.7 2.5 0.4 2.2-2.5 2.4 1.4-1.6 6.2-1.1 5.9 8.9 9 9.1 3.5 2-4.1 3.9 0 1.2 2.6 2.5-0.1 2.4 2.7 3.1 2.9 5.4 1.6 5.5 0.5 4.2 1 4.1-0.2 4.5-2.3 4.6 0.1 0.9 4.7 1.4 5.2 5.5 2.1 5.8 0.4 6.1 2.3 4.4 5-3.4 3.6-0.2 4.9 5.2 7 1.8 8-4.5 0.5-1.1 3.2 0.1 3.7-2.2 4.8-0.3 5.2 2 4.7 1.9 4.9 0.7 3.3 1.9 2 2.2 0.8-0.1 2.8-3.5 3 0.8 4.9 6.6 1.5 5.8 4.7-3.6 8.6-6.9 4-3.8 1.2-2.5 3.8 0.4 6.3-2 4.9-3.7-0.1-3.7 0.9-3.3 3.7-3.6 2.6-10.5-1.5-7.2 1.6-7.2 0.2-3.4-0.4-3.4 0.5-3-0.4-2.9-1-5.8 2.8-5.4 4.1-2.8-1.8-3.8-0.3-3.6-8.9 3.4-6.8 1.4-5.1 0.1-5.7-1.9-3.2-3.2-0.9-6.8-6.9 3.7-12-2.7-8.5-9.7-1.7-5-1.5-11.1 0.4-10.4-1.2-4.1-1.9-2.1-5.8-2.3-4.6-21.6-0.4-4.2-3.3-2.7-5.6-3.5-2.5-1-4.2 7.1-7.5-0.8-14.2 6-1.6 2.6-2.5 2.9-1.9 6.7-6.4-0.9-8.3-3.4-7-1.4-7.9-6.8 0.4-1.8-4.7 5-5.1 4.6-5.9 3.7-7.7-1.3-2.1-2.6-0.4-1.2-6.9 4.7-5.8 2.2-8.9 3.9-3.6 4.3-2.7 0.5-1.3 6 4.6 5.8 3.4 7 0.9 3.6-1 6.6-2.9 2.4-0.4z",
      id: "BGR2249",
      name: "Велико Търново",
    },
  },
  {
    name: "Монтана",
    props: {
      d: "M204.4 88.6l2 7.3-1.7 19.2-2.5 7.9-3.2 7.3-0.4 2.6 0.3 2.7-1.9 2.9-2.8 2-0.9 3.3 4.4 3.9 2 0.7 1.2 2.1 1.7 1.7 2 1.1 0.2 2.7-2.2 2-2.7-1.7-3.8 3-3.6 4.2-3.2 0.2-3.1-0.2-3.9-2.4-6.5 3.8-4.8 7.1 0.3 8.4-1.3 8.6-3.8 5.4-3.3 6.1-3.9 5-1.4 6 11.9 4.3 9.9 5.4-2.1 5-4.1 0.4-4.5 3.7 0.4 5.9-5.8 6.4-7.1 2.8-5.1-1.8-5 1.4-5.5 2.5-14.9-1.3-4.1-3.2-11.5-13.8-7.4 1.7-0.2-2.6-2.7-1.2-7.8-1.7-2.9-1.7-2.2-2.3-4.2-5.8-3.9-3.8-1.4-3.3-0.5-4-0.6-1.7-2-3-11.4-11.5-1.4-1 7.5-6.8 4.2-11.6 0-4.6 3.2-1.8 5.9-2.3 9.2-1 8.9-2.8 1.4-3.7-1-4.2-3.5-0.8-1.7-3.3 2.3-11.8 5.6-8.8 7.7-2.9 1.7-3.1-1.7-4.5-1.1-4.5-3-1.3-3.3-0.8-2.3-3 2.5-3.2 3.9 0.3 4-1.1 1.2-5.2 0.7-7.9 12.7-1.1 4.8-2.1 5.6-1.2 5.9-3.2 14.5-2.1 25.4 1.4 17.3 9.5 4.4 0.8z",
      id: "BGR2250",
      name: "Монтана",
    },
  },
  {
    name: "Враца",
    props: {
      d: "M318.6 105.1l-1.2 10.6-6.6 10.2-9 4.4-5.1-0.5-4.3 3.8-4.4 5.3-3.9 3.8 3.2 6.7 1.8 7.9 4.3 6.9 7.4 1.5 2.9 10.3 0.2 3.8-1.3 4.2-1.3 12.8-7.6 3.9-5.5-0.9-5.4 0.3-3.1 2-1.7 2.3-4.7-1.7-11.8 4.7-2.8 6.4-1.7 4.4 1.4 3 10.8 4.3 1.3 8.5-1.3 8.7-1.7 4.9-4 0-2.8-2.5-2.4 1.7 0.8 6.2 2.2 5.8-2.9 4.4-3.8 2.6-2.7-2-2.6-1.3-15.7-2.9-6-2.6-4.4 2.9-3.5 4.4-4 1.6-4.2-0.2-2.4-3.1-2.5-2.3-6.8 5.7-6.2 3.8-3.3-3.6-2.4-4.9-3.7-4.9-4.3-4-2.7-1.9-2.4-2.3-0.1-4.4 2.3-1.8 3.4-0.1-1.3-2.9-4.9-2.6-5.1-0.2-0.4-5.9 4.5-3.7 4.1-0.4 2.1-5-9.9-5.4-11.9-4.3 1.4-6 3.9-5 3.3-6.1 3.8-5.4 1.3-8.6-0.3-8.4 4.8-7.1 6.5-3.8 3.9 2.4 3.1 0.2 3.2-0.2 3.6-4.2 3.8-3 2.7 1.7 2.2-2-0.2-2.7-2-1.1-1.7-1.7-1.2-2.1-2-0.7-4.4-3.9 0.9-3.3 2.8-2 1.9-2.9-0.3-2.7 0.4-2.6 3.2-7.3 2.5-7.9 1.7-19.2-2-7.3 2.5 0.4 13.5-3.1 3.5 0.7 9.1 5.4 57.3 14.5 28.3-1.4z",
      id: "BGR2251",
      name: "Враца",
    },
  },
  {
    name: "Кюстендил",
    props: {
      d: "M139.8 407.9l2.8-0.4 2.6 1.4 3.7-0.2 3.4-0.8 5.4 12.1 1.8 1.1 1.2 2.1 2.6 2.9 3.5 1.3 2.3 4.5-1.7 2.7-3.2 1.1-2 3.9-5 6.6 2.3 4.9 7.2-1 7-2 4.1 3.2 3.1 5.6 4.8 0.3 4.2 2.1-1.7 4.6-4 3.1-7.9 6.4-9.1 1.5-20.2-6.5-3.1 0.5-3-0.3-3.5 0-7.9 4.1-4.5 3.2-4.9 1.5-5 0.5-4.1-2.9-4.7-1.7-4.7 0.4-2.7-4.1-4 0.2-4.7 7.7-1.2 4.3-3.4-0.6-2.7 2.9-2.1 2.6-0.3-1.5-2.6-2.3-4.5 0.9-1.3-1-0.9-2.9-2.3-1-7.3 0.3-1.1-0.4-2-2.5-4.7-1-9.3-4.7-13.8-10-3.4-3.4-2.4-4.3-2.1-6.2-6.1-4.5-15.7-21.4 3-1.6 6.7-0.1 2.9-0.9 2.3-3.1 2.5-7.9 2.5-3.3 2.4-1.1 2-0.5 1.8-1.2 1.7-3.5 2.2-7.9 0.5-4.5-0.6-3.3-1.2-3-2.1-2.5-4.8-3.6-8.3-7.8-0.7-0.3 0.6-4.3 2-8.7 6.4-0.5 7.8 1.2 6-0.6 4.9 2.6 2.1 6.2 3 5.2 2.8 2.9 0.6 4.5 2.7 3.2 4.7-1.4 2.8 4.2-0.5 6.9 2.5 3.8 3.3 4.1 2.5 4.1 3.6 2.2 3.5 3.5 2.7 4.6 3.7 2.3 4.4-0.8 6.4-5.3 9.6-0.8 3.6-1 8.6 2.9 3 2 2.7-0.4 2-5.9 4.6 4 4.8 0.8 4.2 2.3 4.4-0.6z",
      id: "BGR2252",
      name: "Кюстендил",
    },
  },
  {
    name: "Видин",
    props: {
      d: "M113.8 86.6l-0.7 7.9-1.2 5.2-4 1.1-3.9-0.3-2.5 3.2 2.3 3 3.3 0.8 3 1.3 1.1 4.5 1.7 4.5-1.7 3.1-7.7 2.9-5.6 8.8-2.3 11.8 1.7 3.3 3.5 0.8 1 4.2-1.4 3.7-8.9 2.8-9.2 1-5.9 2.3-3.2 1.8 0 4.6-4.2 11.6-7.5 6.8-3.4-1.7-4.6-0.1-1.5-0.5-1.3-1.5-1.8-3.7-1.2-1.3-1.5-0.5-3.4 0.2-1.7-0.4-1.6-1.1-3.3-4.2-5.3-2.5-2.2-2.1-1.5-4.2-3-10.4-1.9-4.1 0-2.2 0.7-1.6-0.9-3.2 0.7-2.1-1.3-2.7-0.1-5.1-1.1-2.9-1.6-1.6-4.7-2.6-2-1.9-1.5-2.3-2.2-5.6-0.8-4.7 0.6-3.7-0.2-1.7-4.1-5-2.1-6 0.8-4.8 2.1-5.1 1.5-6.8 0.3-6.6 2.5-5.1 0.4-3.5 0-6.4 0.4-2.7 1.9-3.1 3.6-1.5 7.6-1.2 3.5-0.1 1.8-2.3 1.2-3.2 1.9-2.8 3.2-1.2 3.3 0.3 2.8-0.6 1.9-3.4 0-2-1-4.7 0.1-4.7 1.7-6.6-0.8-1.8 0.2-1.4 3-3.3 2.4-4 1.4-1.5 6.9-3.2 34.2 23.5 5.8 2.5 7.2 1 6.9 3.1 1.4 6.8-2.7 6.9-5.6 3.1-3.4 0.4-6.4 2.1-3.3 0.5-3.2 2.1-1.7 5-3.9 16.6 0.1 5 2 4.2 4 3.5 4.9 1.2 21.2-1.9z",
      id: "BGR2253",
      name: "Видин",
    },
  },
  {
    name: "Бургас",
    props: {
      d: "M884.3 305l-0.1 3.5 1.3 4.7 0.9 5.7 0 10.4-0.8 5.6-8.2 0.4-8.5-1.4-7.1-0.2-1.7 0.3-1.1 1.3-1.6 5.4 0.2 2 1.2 1.6 2.4 1.3-4.2 0.7-7 3.1-3.6 0.6-2.9 2.6 0.3 5.7 1.8 6 1.4 3.6-2.6-1.2-14.9-0.4-4.6 2.7-2.1 4.5-1.1 5.3-1.8 5.4-2.9-1.4-1.5 2 0.1 3.2 2 2.1-0.8 2.6 0.8 0.5 1.1-4.4 5 5.3 1.6 0.5 1.9-1.9 1.3-2.5 1.6-1 2.8 2.5 1.5-2.2 1.3 0.3 2.6 1.9 2.2-0.6 3.2-1.6 2.2 0.6-1.1 1.6 0.9 3.8 1.6 2.6 2.5 0.6 3.8-2.7 0.3 2.1 0.9 1 3.2-0.1 0 1.6-3.4 1.5 1.2 2.1 2.2 0.8-1.6 2.2-0.1 2.9 0.9 3 1.8 2.2 2.4 1 3.8-0.8 2.5-0.2 0 1.4-0.9 2.4 2.1 2.3-3.9 3.3-1.9 4 0.7 4 3.9 3.3-1 1.6 5.7 3.7 1.7 3 3.9 4 1.8 1-1.1 3.1 4.8 2.8 1.2 1.4 2.7 6 8.6 5.5 2.4 4 2.2 0.7-0.6 2.4 0.7 2 3.2 3-1.1 1.6 1.8 1.9 1.2 2.8 0.2 3-1 2.6 0.4 2.1-5.7-1.3-2.4-0.7-7.8 0.9-2.1-0.7-4.3-2.1-3.9-1-7.1 0.4-0.4 2.2-0.4 5 2.5 1.5-0.5 1.4-2 0.7-2.1 0.1-2.1-0.7-8.4-4.6-5.8-0.2-12.4 3.2-0.4 2.2-1.4 1-1.2-0.9-1.3 1.7-1.7 1.1-3.4 1.2 1.6 1.4-1.9 1.5 2.2 0.9-2.6 1.1-2.1-1.4-3.8-5.4-2.4-2.1-11.7-6.6-3.8-3.4-3.5-4.1-6.8-10.5-4.3-4.3-5.1-3.1-5.6-1.3-3.5 0.5-2 1.6-4.8 6.7-3.8-1.1-5.4 0-2.5-2-2.7-1.5-2.9-0.9-2.8-0.1-0.1-2.3 1.3-7.9-3.4-5.8-2.2-5.8 1.5-6.3 1.7-4.4-0.6-3.8 0-5 2.2-3.7 5.3-3.1 1-6.7-3.1-6-4.8-3-5-0.1-4.6-1.7-1.8-3.6-1.3-3.9-14.4-10.7-0.2-4.4-3.4-2-6.2-7.8-1.4-2-0.3-2.8-1.2-2.8-2.1-1.9-1.9-5.5-1.3-6.1-6-11.9-9.4-1.5-5.3 2.8-5.5 1.6-3.9-2-3.8-2.5-5.1-1.7-2.2-5.6 4-3.6 5-1.9 3.6-3.3-0.8-5.4 0.7-4 2.4-3.2-1.9-5.6-2.3-5.8 1.5-5-0.5-4.3 11.6-3 1.7 3.3-0.2 4.8 5.6 2.5 6.2-1.2 5.4-3.1 3.4-6.6 2.8-1.8 3.1 0.2 9.1-0.8 5 3.4 5.2-1.1 6.4-0.2 6.7 2.5 7.7-2.1 7.5-3.4 9.2-2 9.5-5.5 7.7 2.5 6.3 6.2 5 8 3.4 1.3 3.7 0.4 7.3 4.4 7.9 1.7 14-3.5 6-3.8 4.3 1.3 4.1 2.5 3.8 3.7 3.4 4.4 4.2 3 4.4 2.3 4.7 1.8 4.7-1 3.4-3.1 3.8-1.5 1.2 0z",
      id: "BGR2254",
      name: "Бургас",
    },
  },
  {
    name: "Ямбол",
    props: {
      d: "M750.9 470.5l-4 1.9-3.5 6.7-3.1 2.2-2.2 0.9-2.8 4.8-1.7 2.1-1.4 0.4-3-0.5-1.7 0.7-1.7 1.8-4.7-0.6-1.7 1.1-3.6 3-1.3 0.7-1.8-0.5-2.9-2.6-1.5-0.7-1.9 0.6-3.5 3.5-1.6 1.1-3 0.4-15-2.5-2.9 0.3-2.6 1.9-4.6 4.9-2.1 1.9-0.4 2.3 2.4 1.9-1.1 5.5-3.5 2-4.1-2-4-2.6-3.2-3.8 0.2-8.1-3.8-5.3-5.5-1.3-3.4 4.6-2.4 3.8-1.4-3.3-1.2-6.7-4.8-3.7-14.6-1.9-13.7-6.9 0.1-6-2.2-5.8 0.1-12.1 5.8-5.1 2.3-7.9-1.2-10.5 8.2-19.8-2.9-2.1-5-0.3-1.1-7.9 5.8-0.5 4.7-3.2-1.6-6.7 3.3-2.1 8.1 3 7.4-2.5 3.3-8.6 2.7-2.5 3.1-1.6-1.7-2.9-1.7-3.5 4.2-3 4.6-1.1 9.2 0.8 9.2-2.9 2.5-3.2 2.5-2.3 2.2-1.6 1.6-2 4.2 0.7 2.1-1.2 1.7-2.5 1.6-6.4-0.5-7.2 9.4 1.5 6 11.9 1.3 6.1 1.9 5.5 2.1 1.9 1.2 2.8 0.3 2.8 1.4 2 6.2 7.8 3.4 2 0.2 4.4 14.4 10.7 1.3 3.9 1.8 3.6 4.6 1.7 5 0.1 4.8 3 3.1 6-1 6.7-5.3 3.1-2.2 3.7 0 5 0.6 3.8-1.7 4.4-1.5 6.3 2.2 5.8 3.4 5.8-1.3 7.9 0.1 2.3z",
      id: "BGR2255",
      name: "Ямбол",
    },
  },
  {
    name: "Разград",
    props: {
      d: "M743.4 118.3l2 7.1-4 3.5-10.5 14.1-2.5-1.6-1.8-2.9-1.8 0.1-1.7 0.9-4 4.5-4.8 4.3-4.1 3.1 2.7 4.3-2.3 4.2-6 2-5.4 3.7-0.9 5.5 2.3 4.6-1.5 6.1-3.4-0.9-3.2 0-0.7 8.5-3.3 1-3.5 0.6-5.7 6-3.9 0-3.8-1.7-10.1 0-1.1-6.4 0.8-8.5-8.1-2.7-9.7-1.3-4-2.8 2.4-7.7-6.5-7-8.2-4.9-7-2.2-6.9 2.6-3.5-5.8 0.5-7.6-0.6-3.3 1.5-2-0.4-5.1 1.8-3.9 4.2 3.9 6-4.5 8.2 1.3 2.1-3.6 2.2-4.8 3.3-1.8-0.5-9 2.9-3.9-3.9-2.1-4.8-0.5-8.3-3.9 0.4-8.8 7.3-4.6 3.5-8.3 11.9-13.1 5.6-0.9 5.3 1.5 4.3 4.8 4.6-1.3 4.1-3.1 3.6 1.8 2.8 3.9 1.2 3.8 3.8 7.2 13.5 4.9 5.3 0.6 2.8 4.2 2.3 4.1 1.2-2.7 2.5-0.4 2.8-1.1 2.2-3.5 2.4-0.4 1.1 4.3 2.5 4 3 2.2 1.6 2.5 2.3 2.1 0.1 5 1.6 2.5 2.8-1.9 2.6 1.6 3.8 5.7 4.7 3.9z",
      id: "BGR2256",
      name: "Разград",
    },
  },
 {
    name: "Търговище",
    props: {
      d: "M699.1 181.8l7.9 1.7 1.4 7.7-3 2.2-2.2 3.2-1.5 5.1-0.9 5 5.2 10-0.3 9.4-2.3 0-2.3 1.9-1.8 5.7-2.3 4.2-5.1 2-3.3 5.4 1.7 3.1 3 1.8-1.4 3.1-3.4 0.8-0.4 3.3-1.4 3.7-2.8 0.4-2.1 2.1 2.2 3.1 0.3 3.7-4 1-2.2 2.3-2.7 1.1-5-2.6-4.7-3.3-3.5-1.4-3.6 2.3-3.9 0.9-4.1-0.2-6-4.1-1.2 2.1-1.6 1.1-1.1-2.4 0.7-2.6-4-1.8-4.4 2-2.6 0.1-2.8-0.6 0 6.5-1 2.9-2.7 1.2-2.3 2.8-2 3.1-4.1 1.5-4.2 0.6-5.8-4.7-6.6-1.5-0.8-4.9 3.5-3 0.1-2.8-2.2-0.8-1.9-2-0.7-3.3-1.9-4.9-2-4.7 0.3-5.2 2.2-4.8-0.1-3.7 1.1-3.2 4.5-0.5-1.8-8-5.2-7 0.2-4.9 3.4-3.6-4.4-5-6.1-2.3 0.3-5-0.6-4.8-3.6-1.7-4.2-0.6-2.9-4.4-0.9-5 4-1.1 4.1-3.1 3.7-3.9 2.2-4.7 0.9-7.1 5-2.4 3.7-2.8 3.7 0.7 3.5-0.7 4 0.7 3.2-1.8 6.9-2.6 7 2.2 8.2 4.9 6.5 7-2.4 7.7 4 2.8 9.7 1.3 8.1 2.7-0.8 8.5 1.1 6.4 10.1 0 3.8 1.7 3.9 0 5.7-6 3.5-0.6 3.3-1 0.7-8.5 3.2 0 3.4 0.9z",
      id: "BGR2257",
      name: "Търговище",
    },
  },
 {
    name: "Шумен",
    props: {
      d: "M775.2 125l9 7.5 3.3 6.7 4.6 2.2 6.3-2 6.4-1.2 3.7 2.3 1.6 6.1-12.8 3.7-8.5 7.6 7.8 2.6-1.8 4.9-1.9 2.7 1.9 1.7 2 3-5.7 8.3-3.2 3.7-1.4 4.1 0.1 5.5 2.8 3.9 2.1 3.7-3.9 3.2-4.4 1.6 0.1 5.5-0.3 4.3-5.5 1.3-5.6 0.6-3.6 2.7-0.6 5.4 4.2 4.1 5.3 2.1-0.2 5.2-5.1 2.9-2.7 4.7 3.7 4.4-4.4 3.6 7.3 2.6 0.8 5.3-7.5 1.9-9.5 5.3-3.8 2.9 2.4 5.4 4.1 4.9-7.5 3.4-7.7 2.1-6.7-2.5-6.4 0.2-5.2 1.1-5-3.4-9.1 0.8-3.1-0.2-2.8 1.8-3.4 6.6-5.4 3.1-6.2 1.2-5.6-2.5 0.2-4.8-1.7-3.3-11.6 3-3.2-7.4 3.5-6.3 2.7-1.1 2.2-2.3 4-1-0.3-3.7-2.2-3.1 2.1-2.1 2.8-0.4 1.4-3.7 0.4-3.3 3.4-0.8 1.4-3.1-3-1.8-1.7-3.1 3.3-5.4 5.1-2 2.3-4.2 1.8-5.7 2.3-1.9 2.3 0 0.3-9.4-5.2-10 0.9-5 1.5-5.1 2.2-3.2 3-2.2-1.4-7.7-7.9-1.7 1.5-6.1-2.3-4.6 0.9-5.5 5.4-3.7 6-2 2.3-4.2-2.7-4.3 4.1-3.1 4.8-4.3 4-4.5 1.7-0.9 1.8-0.1 1.8 2.9 2.5 1.6 10.5-14.1 4-3.5-2-7.1 8.5-4.9 6.4 4.1 6.3 7.3 10.6 0.2z",
      id: "BGR2258",
      name: "Шумен",
    },
  },
 {
    name: "Добрич",
    props: {
      d: "M916.6 194.1l-0.5-0.2-5.6 0.6-5.6 0.1-3.3-4.3-4.3-4.5-5.1-0.3-4.7 2.2-2.6 3-3.2 1.1-9.5-4.8-7.8-9.7-3-5.5-4.1-3.7-5.9-1.2-6.3-5.9-5.5-8-2.5 1-1.7 4.2-3 1.5-3.2 0.4-3 2.4-4-2-1-5.3-4.8-6.7-6.3-1.9-1.6-6.1-3.7-2.3-6.4 1.2-6.3 2-4.6-2.2-3.3-6.7-9-7.5 3.1 0.8 2.8-1.2-3.8-6 0.4-9.8 7.7-7.1 2-4.4 3.2-1.8 7.3 6 7-0.1-2-10.9 4-3.2 5-1 8.1 3 6.4-4.7-0.3-6.9 1.8-6.7 4-1.1 3.6 3.2 4.7-0.9 0.4-4.2-0.9-2.6 3-0.1 2.9-3.4-0.8-4.9 3-2.5 4.3 0.1 6.3 8.6 10.4-2.6 11.1-6.3 8.8-1 3.8 6.4 7.2 25.5 5.4 4.2 32.9 15.1 34.1 5.9 22.9-1.3-0.4 3-0.5 26.8 0.4 2.8 1.7 3.9 2.7 5.1 0.1 3.2-1.4 5.6-2.6 7-2.9 5.9-2.4 2.5-1.6 0.9-7.4 7-1.5 2.2-0.9 2.7-0.1 3.8-2.2-3-7.1-4-3.3-3.4-4.1-1.6-7.5-1.5-3.5 0.7-6 3.1-3 0.7-10.4-0.4-3.3 0.4-6.1 3.6-4.3 6.1-0.8 1.9z",
      id: "BGR2259",
      name: "Добрич",
    },
  },
 {
    name: "Варна",
    props: {
      d: "M916.6 194.1l-8.9 23.3-2.1 3.5-2.8 2.1-10.9 3-1.8 1.2-2.7 0.4 0 1.5 5.6 4.2 1.1 1.6-0.2 2.6-3.1 9.5-2.2 10.6-1.1 2-2.6 3-0.7 2.5 0.3 4.9 2.4 9.9 0.5 4.5-0.8 14.8 0.8 3.2-3.1 2.6-1.2 0-3.8 1.5-3.4 3.1-4.7 1-4.7-1.8-4.4-2.3-4.2-3-3.4-4.4-3.8-3.7-4.1-2.5-4.3-1.3-6 3.8-14 3.5-7.9-1.7-7.3-4.4-3.7-0.4-3.4-1.3-5-8-6.3-6.2-7.7-2.5-9.5 5.5-9.2 2-4.1-4.9-2.4-5.4 3.8-2.9 9.5-5.3 7.5-1.9-0.8-5.3-7.3-2.6 4.4-3.6-3.7-4.4 2.7-4.7 5.1-2.9 0.2-5.2-5.3-2.1-4.2-4.1 0.6-5.4 3.6-2.7 5.6-0.6 5.5-1.3 0.3-4.3-0.1-5.5 4.4-1.6 3.9-3.2-2.1-3.7-2.8-3.9-0.1-5.5 1.4-4.1 3.2-3.7 5.7-8.3-2-3-1.9-1.7 1.9-2.7 1.8-4.9-7.8-2.6 8.5-7.6 12.8-3.7 6.3 1.9 4.8 6.7 1 5.3 4 2 3-2.4 3.2-0.4 3-1.5 1.7-4.2 2.5-1 5.5 8 6.3 5.9 5.9 1.2 4.1 3.7 3 5.5 7.8 9.7 9.5 4.8 3.2-1.1 2.6-3 4.7-2.2 5.1 0.3 4.3 4.5 3.3 4.3 5.6-0.1 5.6-0.6 0.5 0.2z",
      id: "BGR2260",
      name: "Варна",
    },
  },
  {
    name: "Силистра",
    props: {
      d: "M852.1 54.6l-4.3-0.1-3 2.5 0.8 4.9-2.9 3.4-3 0.1 0.9 2.6-0.4 4.2-4.7 0.9-3.6-3.2-4 1.1-1.8 6.7 0.3 6.9-6.4 4.7-8.1-3-5 1-4 3.2 2 10.9-7 0.1-7.3-6-3.2 1.8-2 4.4-7.7 7.1-0.4 9.8 3.8 6-2.8 1.2-3.1-0.8-10.6-0.2-6.3-7.3-6.4-4.1-8.5 4.9-4.7-3.9-3.8-5.7-2.6-1.6-2.8 1.9-1.6-2.5-0.1-5-2.3-2.1-1.6-2.5-3-2.2-2.5-4-1.1-4.3-2.4 0.4-2.2 3.5-2.8 1.1-2.5 0.4-1.2 2.7-2.3-4.1-2.8-4.2-5.3-0.6-13.5-4.9-3.8-7.2-1.2-3.8-2.8-3.9-3.6-1.8-4.1 3.1-4.6 1.3-4.3-4.8-5.3-1.5-5.6 0.9-2.1-8.8-1.1-6.7-4.2-0.7-2.4-5.1-0.4-2.1 45-6.7 5.3-1.9 4.7-0.8 3.2-2 1.8-0.4 7.2 0 5.7-1.8 15.1-9 18.7-1.9 4.2-2.7 11.6 7.3 16.8 3.4 3.4 1.9 3.8-0.4 2.9 2.2-0.8 5.1 3.4 3.8 9 4.3 1.9 1.7 3.1 5.5 1.7 1.2 30.4-0.2 9.4-3 3.6 1.3 3.2 6.7 1 1.4z",
      id: "BGR2261",
      name: "Силистра",
    },
  },
  {
    name: "Русе",
    props: {
      d: "M647 63l-11.9 13.1-3.5 8.3-7.3 4.6-0.4 8.8 8.3 3.9 4.8 0.5 3.9 2.1-2.9 3.9 0.5 9-3.3 1.8-2.2 4.8-2.1 3.6-8.2-1.3-6 4.5-4.2-3.9-1.8 3.9 0.4 5.1-1.5 2 0.6 3.3-0.5 7.6 3.5 5.8-3.2 1.8-4-0.7-3.5 0.7-3.7-0.7-3.7 2.8-5 2.4-0.9 7.1-2.2 4.7-3.7 3.9-4.1 3.1-4 1.1 0.9 5 2.9 4.4 4.2 0.6 3.6 1.7 0.6 4.8-0.3 5-5.8-0.4-5.5-2.1-1.4-5.2-0.9-4.7-4.6-0.1-4.5 2.3-4.1 0.2-4.2-1-5.5-0.5-5.4-1.6-3.1-2.9-2.4-2.7-2.5 0.1-1.2-2.6-3.9 0-2 4.1-9.1-3.5-8.9-9 1.1-5.9 1.6-6.2-2.4-1.4-2.2 2.5-2.5-0.4-0.2-2.7-8.2-6.2-0.8-4.8 3.5-2.7 3.4-7.6-2.9-9.7-1.2-2.5 1-0.2 7.2 0.4 3.6-0.4 3-1.5 3-0.7 3.6-1.6 3.4-2.1 2.5-2.4 2.8-2 10.9-0.4 6.6-2.9 3.7-6.2 0.3-0.8 5.3-5.5 4.8-2.7 7.5-9.6 1.3-3.2 1.5-2.6 19.2-14.1 1.2-3.5 2.8-4.2 5.9-6.7 5.5-3 12.8-3.3 12.6-5.6 3.5-0.5 0.4 2.1 2.4 5.1 4.2 0.7 1.1 6.7 2.1 8.8z",
      id: "BGR2262",
      name: "Русе",
    },
  },
  {
    name: "Благоевград",
    props: {
      d: "M227.1 450.1l1.6 4.2 3.1 2.5 2.8 3 1 4.4-0.5 3.4-1.9 2.5 0 4.8 3.1 3.7-4.6 5.3-4 9.5 0.2 4.3-0.9 3.5 1.9 3.9 2.5 3.4 0.9 9.7 4.2 7.9 3.9 2.5 3.5 3.6 2.8 0.6 3.1-0.8 2 2.5 1.7 3.4 18.1 12.5 1.9 3.7 3 1.4 2.3 1.2 1.5 2.7 4.2 2.9-3.1 3.8-4.1 2.7 19.1 16.3-2.5 2.2-0.7 1.4-0.7 3.3-1.6 0.7-1.4-2.1-1.3-1-4.7-1.4-6.4-0.6-4.6 2.2 0.8 11.6-0.1 1.8-1 1.6-1.8 0.9-1.8-0.4-3.6-2.4-0.6 1.6-2.5 1.3-2.6 2.6-2.4 0.2-7.5-5.5-1.3-0.2-4.3 4-2.6 1.3-3.3 0.8-3.3 0.4-2.9-0.1-2.3 1.1-3.6 6-2.6 0.7-5.3-1.2-5.3 0.1-3 1.1-4.1 4.1-2.4 1.6-5.3-0.2-10.5-5.5-15.8-0.5-3 1-4.8 3.6-2.9 1.5-3.4 0.4-1.6-1.6-1.5-2.4-3-2.1-2.8 0.2-3.7 1.6-3.6 2.3-2.3 2.2-0.5 1.6-0.3 3.9-0.8 2-3.2 2.5-3.6 1.1-6.7 0.8-31.6-4.9 3.8-3 0.6-4-0.8-4.5-0.3-4.5 0.5-1.3 1.8-2.3 0.2-2.2-1.1-3.2-0.5-18.2 0.9-4.8-0.4-1.4-2-3.6-0.3-2.9 0.2-3.1 1.2-5.4 0.8-0.7 3.4-1.3 1.5-4.1 3.5-5.7 1.8-5-0.1-5-2.9-4.5-1.7-0.8-3.7-0.2-1.8-0.8-1-1.6-3.4-8.1-1.7-7.3-1-2.5-3.3-2.7-0.4-1.6-0.2-4.6-1.7-4.9-1.2-5-0.2-5.1-0.4-2.3-1.3-2.4-0.2-3 2.1-2.6 2.7-2.9 3.4 0.6 1.2-4.3 4.7-7.7 4-0.2 2.7 4.1 4.7-0.4 4.7 1.7 4.1 2.9 5-0.5 4.9-1.5 4.5-3.2 7.9-4.1 3.5 0 3 0.3 3.1-0.5 20.2 6.5 9.1-1.5 7.9-6.4 8.6 3.2 5.3-6.5 3.1-7.8 5.9-0.8 4.3-1.7 5.4-1 3.1 1.4 2.9-1.4 1.8-2.4 2.5 0.1z",
      id: "BGR3002",
      name: "Благоевград",
    },
  },
];

export const data = [
  {
    name: "Иван Палев",
    position: "Регионален мениджър",
    phoneNumber: "359885882858",
    email: "i.palev@synergonenergy.bg",
    location: "Благоевград",
  },
  {
    name: "Керка Иванова",
    position: "Търговски представител",
    phoneNumber: "359882751535",
    email: "kika.ivanova@synergonenergy.bg",
    location: "Бургас",
  },
  {
    name: "Цветан Иванчев",
    position: "Търговски представител",
    phoneNumber: "359892601906",
    email: "ts.ivanchev@synergonenergy.bg",
    location: "Варна",
  },
  {
    name: "Детелина Иванова",
    position: "Търговски представител",
    phoneNumber: "359892601915",
    email: "d.ivanova@synergonenergy.bg",
    location: "Велико Търново",
  },
  {
    name: "Тони Цветков",
    position: "Търговски представител",
    phoneNumber: "359892601950",
    email: "t.tsvetkov@synergonenergy.bg",
    location: "Видин",
  },
  {
    name: "Явор Андров",
    position: "Търговски представител",
    phoneNumber: "359888058839",
    email: "y.androv@synergonenergy.bg",
    location: "Враца",
  },
  {
    name: "Детелина Иванова",
    position: "Търговски представител",
    phoneNumber: "359892601915",
    email: "d.ivanova@synergonenergy.bg",
    location: "Габрово",
  },
  {
    name: "Анелия Костадинова",
    position: "Търговски представител",
    phoneNumber: "359892601934",
    email: "a.kostadinova@synergonenergy.bg",
    location: "Добрич",
  },
  {
    name: "Кирил Добрев",
    position: "Търговски представител",
    phoneNumber: "359882392468",
    email: "k.dobrev@synergonenergy.bg",
    location: "Кърджали",
  },
  {
    name: "Иван Палев",
    position: "Регионален мениджър",
    phoneNumber: "359885882858",
    email: "i.palev@synergonenergy.bg",
    location: "Кюстендил",
  },
  {
    name: "Николай Костов",
    position: "Търговски представител",
    phoneNumber: "359892601912",
    email: "n.kostov@synergonenergy.bg",
    location: "Ловеч",
  },
  {
    name: "Коста Марков",
    position: "Търговски представител",
    phoneNumber: "359892601917",
    email: "k.markov@synergonenergy.bg",
    location: "Монтана",
  },
  {
    name: "Петър Георгиев",
    position: "Търговски представител",
    phoneNumber: "359892601924",
    email: "p.georgiev@synergonenergy.bg",
    location: "Пазарджик",
  },
  {
    name: "Ния Тонева",
    position: "Търговски представител",
    phoneNumber: "359895649013",
    email: "n.toneva@synergonenergy.bg",
    location: "Перник",
  },
  {
    name: "Николай Костов",
    position: "Търговски представител",
    phoneNumber: "359892601912",
    email: "n.kostov@synergonenergy.bg",
    location: "Плевен",
  },
  {
    name: "Костадин Стефанов",
    position: "Регионален мениджър",
    phoneNumber: "359892601922",
    email: "k.stefanov@synergonenergy.bg",
    location: "Пловдив",
  },
  {
    name: "Илкер Мустафов",
    position: "Търговски представител",
    phoneNumber: "359892601927",
    email: "i.mustafov@synergonenergy.bg",
    location: "Разград",
  },
  {
    name: "Полина Велева",
    position: "Търговски представител",
    phoneNumber: "359892601931",
    email: "p.veleva@synergonenergy.bg",
    location: "Русе",
  },
  {
    name: "Ирена Тодорова",
    position: "Търговски представител",
    phoneNumber: "359892601907",
    email: "i.todorova@synergonenergy.bg",
    location: "Силистра",
  },
  {
    name: "Диян Велев",
    position: "Търговски представител",
    phoneNumber: "359892601900",
    email: "d.velev@synergonenergy.bg",
    location: "Сливен",
  },
  {
    name: "Райчо Чипилов",
    position: "Търговски представител",
    phoneNumber: "359892601955",
    email: "r.chipilov@synergonenergy.bg",
    location: "Смолян",
  },
  {
    name: "Румен Димитров",
    position: "Търговски представител",
    phoneNumber: "359892601926",
    email: "r.dimitrov@synergonenergy.bg",
    location: "София (област)",
  },
  {
    name: "Артур Стефчов / Митко Апостолов",
    position: "Регионален мениджър",
    phoneNumber: "359892601903 / 359892601936",
    email: "a.stefchov@synergonenergy.bg / m.apostolov@synergonenergy.bg",
    location: "София",
  },
  {
    name: "Костадин Стефанов",
    position: "Търговски представител",
    phoneNumber: "359892601922",
    email: "k.stefanov@synergonenergy.bg",
    location: "Стара Загора",
  },
  {
    name: "Илкер Мустафов",
    position: "Търговски представител",
    phoneNumber: "359892601927",
    email: "i.mustafov@synergonenergy.bg",
    location: "Търговище",
  },
  {
    name: "Тома Ташев",
    position: "Търговски представител",
    phoneNumber: "359892601923",
    email: "t.tashev@synergonenergy.bg",
    location: "Хасково",
  },
  {
    name: "Пламена Христова",
    position: "Търговски представител",
    phoneNumber: "359892601944",
    email: "p.hristova@synergonenergy.bg",
    location: "Шумен",
  },
  {
    name: "Диян Велев",
    position: "Търговски представител",
    phoneNumber: "359892601900",
    email: "d.velev@synergonenergy.bg",
    location: "Ямбол",
  },
];

