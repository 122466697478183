import React from 'react';
import './styles.scss';
import { HeroSection, AboutSection, OfferBanner, ProductsSection, PartnersSection, InfoSection } from '../../components';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import { changeTab } from '../../actions/general';
import { Helmet } from 'react-helmet';

export default function Home() {
  const dispatch = useDispatch();

  const language = useSelector((state) => state.general.language);

  useEffect(() => {
    window.scrollTo(0, 0, { behavour: 'smooth' })
    dispatch(changeTab('home'))
  }, [dispatch])

  return (
    <div className='screen-wrapper home'>
      {/* <h1>{t('test')}</h1> */}
      <Helmet>
        <title>Synergon Energy Home</title>
        <meta name="robots" content="index, follow"></meta>
      </Helmet>
      <div className='screen-inner-wrapper'>
        <HeroSection />
        <AboutSection />
        <OfferBanner />
        <ProductsSection />
        <OfferBanner />
        <PartnersSection />
        {language === 'bg' && <InfoSection home />}
        <OfferBanner />
      </div>
    </div>
  )
}
