import React from 'react';
import './styles.scss';
import { OfferBanner } from '../../components';
import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

export default function Users() {
    const navigate = useNavigate();
    const [ hoveredCard, setHoveredCard ] = useState('');
    const { t } = useTranslation();

    useEffect(() => {
      window.scrollTo(0, 0, { behavour: 'smooth' })
    }, []);

  return (
    <div className='screen-wrapper users'>
      <div className='screen-inner-wrapper'>
        <div className='screen-header-wrapper'>
            <div className='flex padding' onClick={() => navigate(-1)}>
                <img src={require('../../assets/icons/arrow.svg').default} alt='arrow back' /> 
                <span>{t('general.back')}</span>
            </div>

            <div className='section-wrapper text'>
                <div className='title-wrapper'>
                  <h1>{t('productsAndServices.users')}</h1>
                  <div className='flex'>
                    <div className='line' />
                    <h2>{t('users.usersSubtitle')}</h2>
                  </div>
                </div>
    
                <div className='text-wrapper'>
                    <p>{t('users.usersText')}</p>
                </div>
           </div>
        </div>

        <div className='screen-main-wrapper desktop'>
           <div className={`section-wrapper card ${hoveredCard === 'first' ? 'hidden' : ''}`}
            onMouseOver={() => setHoveredCard('first')}  onMouseLeave={() => setHoveredCard('')}>
               <img className='icon' src={require('../../assets/icons/users-card-1.svg').default} alt='card' />
               <h2>{t('users.cardOneTitle')}</h2>
               <p>{t('users.cardOneText')}</p>

               <div className={`flex col ${hoveredCard === 'first' ? 'hovered' : 'hidden'}`}>
                 <div className='flex'>
                    <div className='line orange' />
                    <h5>{t('users.cardOneHoveredTextOne')}</h5>
                  </div>
                 <div className='flex'>
                    <div className='line orange' />
                    <h5>{t('users.cardOneHoveredTextTwo')}</h5>
                  </div>
                 <div className='flex'>
                    <div className='line orange' />
                    <h5>{t('users.cardOneHoveredTextThree')}</h5>
                  </div>

                  <button className="primary-bttn" onClick={() => navigate('/offers-initial')}>{t('general.getOffer')}</button>
              </div>
           </div>

           <div className={`section-wrapper card ${hoveredCard === 'second' ? 'hidden' : ''}`}
            onMouseOver={() => setHoveredCard('second')}  onMouseLeave={() => setHoveredCard('')}>
               <img className='icon' src={require('../../assets/icons/users-card-2.svg').default} alt='card' />
               <h2>{t('users.cardTwoTitle')}</h2>
               <p>{t('users.cardTwoText')}</p>

               <div className={`flex col ${hoveredCard === 'second' ? 'hovered' : 'hidden'}`}>
                 <div className='flex'>
                    <div className='line orange' />
                    <h5>{t('users.cardTwoHoveredTextOne')}</h5>
                  </div>
                 <div className='flex'>
                    <div className='line orange' />
                    <h5>{t('users.cardTwoHoveredTextTwo')}</h5>
                  </div>
                 <div className='flex'>
                    <div className='line orange' />
                    <h5>{t('users.cardTwoHoveredTextThree')}</h5>
                  </div>

                  <button className="primary-bttn" onClick={() => navigate('/offers-initial')}>{t('general.getOffer')}</button>
              </div>
           </div>

           <div className={`section-wrapper card ${hoveredCard === 'third' ? 'hidden' : ''}`}
            onMouseOver={() => setHoveredCard('third')}  onMouseLeave={() => setHoveredCard('')}>
               <img className='icon' src={require('../../assets/icons/users-card-3.svg').default} alt='card' />
               <h2>{t('users.cardThreeTitle')}</h2>
               <p>{t('users.cardThreeText')}</p>

               <div className={`flex col ${hoveredCard === 'third' ? 'hovered' : 'hidden'}`}>
                 <div className='flex'>
                    <div className='line orange' />
                    <h5>{t('users.cardThreeHoveredTextOne')}</h5>
                  </div>
                 <div className='flex'>
                    <div className='line orange' />
                    <h5>{t('users.cardThreeHoveredTextTwo')}</h5>
                  </div>
                 <div className='flex'>
                    <div className='line orange' />
                    <h5>{t('users.cardThreeHoveredTextThree')}</h5>
                  </div>

                  <button className="primary-bttn" onClick={() => navigate('/offers-initial')}>{t('general.getOffer')}</button>
              </div>
           </div>

           <div className={`section-wrapper card ${hoveredCard === 'forth' ? 'hidden' : ''}`}
            onMouseOver={() => setHoveredCard('forth')}  onMouseLeave={() => setHoveredCard('')}>
               <img className='icon' src={require('../../assets/icons/users-card-3.svg').default} alt='card' />
               <h2>{t('users.cardFourTitle')}</h2>
               <p>{t('users.cardFourText')}</p>

               <div className={`flex col ${hoveredCard === 'forth' ? 'hovered' : 'hidden'}`}>
                 <div className='flex'>
                    <div className='line orange' />
                    <h5>{t('users.cardFourHoveredTextOne')}</h5>
                  </div>
                 <div className='flex'>
                    <div className='line orange' />
                    <h5>{t('users.cardFourHoveredTextTwo')}</h5>
                  </div>
                 <div className='flex'>
                    <div className='line orange' />
                    <h5>{t('users.cardFourHoveredTextThree')}</h5>
                  </div>

                  <button className="primary-bttn" onClick={() => navigate('/offers-initial')}>{t('general.getOffer')}</button>
              </div>
           </div>

          <div className={`section-wrapper card ${hoveredCard === 'fifth' ? 'hidden' : ''}`}
            onMouseOver={() => setHoveredCard('fifth')} onMouseLeave={() => setHoveredCard('')}>
            <img className='icon' src={require('../../assets/icons/users-card-1.svg').default} alt='card' />
            <h2>{t('users.cardFiveTitle')}</h2>
            <p>{t('users.cardFiveText')}</p>

            <div className={`flex col ${hoveredCard === 'fifth' ? 'hovered' : 'hidden'}`}>
              <div className='flex'>
                <div className='line orange' />
                <h5>{t('users.cardFiveHoveredTextOne')}</h5>
              </div>
              <div className='flex'>
                <div className='line orange' />
                <h5>{t('users.cardFiveHoveredTextTwo')}</h5>
              </div>
              <div className='flex'>
                <div className='line orange' />
                <h5>{t('users.cardFiveHoveredTextThree')}</h5>
              </div>
              <div className='flex'>
                <div className='line orange' />
                <h5>{t('users.cardFiveHoveredTextFour')}</h5>
              </div>

              <button className="primary-bttn" onClick={() => navigate('/offers-initial')}>{t('general.getOffer')}</button>
            </div>
          </div>
        </div>

        <div className='screen-main-wrapper mobile'>
          <div className='section-wrapper card'>
               <img className='icon' src={require('../../assets/icons/users-card-1.svg').default} alt='card' />
               <h2>{t('users.cardOneTitle')}</h2>
               <p>{t('users.cardOneText')}</p>

               <div className='flex col'>
                 <div className='flex'>
                    <div className='line orange' />
                    <h5>{t('users.cardOneHoveredTextOne')}</h5>
                  </div>
                 <div className='flex'>
                    <div className='line orange' />
                    <h5>{t('users.cardOneHoveredTextTwo')}</h5>
                  </div>
                 <div className='flex'>
                    <div className='line orange' />
                    <h5>{t('users.cardOneHoveredTextThree')}</h5>
                  </div>
              </div>
          </div>

          <div className='section-wrapper card'>
               <img className='icon' src={require('../../assets/icons/users-card-2.svg').default} alt='card' />
               <h2>{t('users.cardTwoTitle')}</h2>
               <p>{t('users.cardTwoText')}</p>

               <div className='flex col'>
                 <div className='flex'>
                    <div className='line orange' />
                    <h5>{t('users.cardTwoHoveredTextOne')}</h5>
                  </div>
                 <div className='flex'>
                    <div className='line orange' />
                    <h5>{t('users.cardTwoHoveredTextTwo')}</h5>
                  </div>
                 <div className='flex'>
                    <div className='line orange' />
                    <h5>{t('users.cardTwoHoveredTextThree')}</h5>
                  </div>
              </div>
          </div>

          <div className='section-wrapper card'>
               <img className='icon' src={require('../../assets/icons/users-card-3.svg').default} alt='card' />
               <h2>{t('users.cardThreeTitle')}</h2>
               <p>{t('users.cardThreeText')}</p>

               <div className='flex col'>
                 <div className='flex'>
                    <div className='line orange' />
                    <h5>{t('users.cardThreeHoveredTextOne')}</h5>
                  </div>
                 <div className='flex'>
                    <div className='line orange' />
                    <h5>{t('users.cardThreeHoveredTextTwo')}</h5>
                  </div>
                 <div className='flex'>
                    <div className='line orange' />
                    <h5>{t('users.cardThreeHoveredTextThree')}</h5>
                  </div>
              </div>
           </div>

           <div className='section-wrapper card'>
               <img className='icon' src={require('../../assets/icons/users-card-3.svg').default} alt='card' />
               <h2>{t('users.cardFourTitle')}</h2>
               <p>{t('users.cardFourText')}</p>

               <div className='flex col'>
                 <div className='flex'>
                    <div className='line orange' />
                    <h5>{t('users.cardFourHoveredTextOne')}</h5>
                  </div>
                 <div className='flex'>
                    <div className='line orange' />
                    <h5>{t('users.cardFourHoveredTextTwo')}</h5>
                  </div>
                 <div className='flex'>
                    <div className='line orange' />
                    <h5>{t('users.cardFourHoveredTextThree')}</h5>
                  </div>

              </div>
           </div>
        </div>

        <OfferBanner />
      </div>
    </div>
  )
}
