import React from 'react';
import './styles.scss';
import { useTranslation } from 'react-i18next';

export default function OfferQueryModal({ onClick }) {
  const { t } = useTranslation();

  return (
    <div className="modal-wrapper successful-offer-query">
      <div className="content-wrapper">
        <div className="top-wrapper">
          <img
            src={require("../../../assets/icons/success-check.svg").default} alt='success check'
          />
          <h1>{t('offers.successfulQuery')}</h1>
          <p>{t('offers.successfulQueryText')}</p>
        </div>

        <button onClick={onClick}>{t('offers.continue')}</button>
      </div>
    </div>
  )
}
