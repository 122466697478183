import React from 'react';
import './styles.scss';
import RadioButton from '../../RadioButton/RadioButton';
import { useState } from 'react';
import { FileDrop } from "react-file-drop";
import { OfferQueryModal } from '../../Modals';
import { useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { setLoading, setModal } from '../../../actions/general';
import { UploadButton } from '../../UploadButton';
import { sendInquiry } from '../../../actions/inquiries';
import LoadingModal from '../../Modals/LoadingModal/LoadingModal';
import { useTranslation } from 'react-i18next';

export default function OfferInvoice({ payload, onChange, type}) {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const modal = useSelector(state => state.general.modal)
    const loading = useSelector(state => state.general.loading)
    const { t } = useTranslation();
    const [ invoiceData, setInvoiceData ] = useState([])

    const isDisabled = () => {
      if (['fullName', 'email', 'phoneNumber', 'firmName', 'uic', 'policyAgreement'].some((f)=> !payload?.[f]) )return true
      // if (!/^[\w\.]+@([\w-]+\.)+([\w-]{2,}|(\p{Cyrillic}+))$/gi.test(payload.email) ) return true
      // if (!/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,63}(\\p{IsCyrillic})*?$/gi.test(payload.email)) return true
      if (!/^[а-яА-ЯёЁa-zA-Z0-9._%+-]+@[а-яА-ЯёЁa-zA-Z0-9.-]+\.[а-яА-ЯёЁa-zA-Z]{2,}$/gi.test(payload.email)) return true

      // if (type === 'invoice' && !payload.invoice.length) return true
      if (type === 'manual' && !payload.address) return true

      return false
    }

    const fileHandler = async (files) => {
      const extension = files[0].name.split(".")[1]?.toLowerCase();
      if (extension !== undefined) {
        const fNames = Object.keys(files).map((name) => {
          return {
            name: files[name].name,
            icon: files[name].name.split(".")[1]?.toUpperCase().trim()
          };
        });
        const newInvoiceNames = fNames.flat().map(n => n.name)
        await onChange({ invoice: [...payload.invoice, newInvoiceNames[0] ] })
        setLoading(false)
      } else {
        alert("file type not supported");
      }
    };

  return (
    <div className='offer-inputs-wrapper users-invoice'>
        <div className='section-wrapper'>
            <div className='section-title'>
               <div className='flex start col'>
                 <h2>{t('offers.inputTitleContactData')}</h2>
               </div>
            </div>

            <div className='section-content'>
                <div className='input-wrapper'>
                  <p>{t('offers.inputTitleFullName')} <span className="orange">*</span></p>
                  <input
                    value={payload?.fullName}
                    onChange={(e) => onChange({ fullName: e.target.value })}
                    // className={`input ${!payload?.fullName ? 'red' : ''}`}
                  ></input>
                </div>
                
                <div className='input-wrapper'>
                  <p>{t('offers.inputTitleEmail')} <span className="orange">*</span></p>
                  <input
                    value={payload?.email}
                    onChange={(e) => onChange({ email: e.target.value })}
                  ></input>
                </div>

                <div className='input-wrapper'>
                  <p>{t('general.phoneNumber')} <span className="orange">*</span></p>
                  <input
                    value={payload?.phoneNumber}
                    onChange={(e) => onChange({ phoneNumber: e.target.value })}
                  ></input>
                </div>
                
                <div className='input-wrapper last'>
                  <p>{t('offers.inputTitleFirmName')} <span className="orange">*</span></p>
                  <input
                    value={payload?.firmName}
                    onChange={(e) => onChange({ firmName: e.target.value })}
                  ></input>
                </div>

                <div className='flex start'>
                    <div className={`flex ${type === 'invoice' ? 'quarter' : 'half'}`}>
                       <div className='input-wrapper'>
                        <p>{t('offers.inputTitleUic')} <span className="orange">*</span></p>
                        <input
                          value={payload?.uic}
                          onChange={(e) => onChange({ uic: e.target.value })}
                        ></input>
                    </div>
      
                    {type === 'manual' &&
                      <div className='input-wrapper'>
                        <p>{t('general.address')} <span className="orange">*</span></p>
                        <input
                          value={payload?.address}
                          onChange={(e) => onChange({ address: e.target.value })}
                        ></input>
                       </div>}
                    </div>
                </div>
            </div>
        </div>

        <div className='section-wrapper'>
            {type === 'invoice' ?
            <div className='section-title'>
               <div className='flex start col'>
                 <h2>{t('offers.invoice')}</h2>
                 <p>{t('offers.invoiceText')}</p>
               </div>
            </div> :
            <div className='section-title'>
               <div className='flex start col'>
                 <h2>{t('offers.additionalData')}</h2>
               </div>
            </div>}

            <div className='section-content file-drop'>
                {type === 'invoice' &&

                  <FileDrop onDrop={(f) => fileHandler(f)}>
                   <div className='input-wrapper upload file-drop-target'>
                     <span><img src={require('../../../assets/icons/upload-grey.svg').default} alt='upload button'/></span>
                      <div className="placeholder desktop">
                      {t('offers.placeFilesHere')}
                      </div>
                    <div className="grey desktop">{t("offers.or")}</div>
                    
                    <UploadButton
                      inputProps={{ multiple: true , accept: "*" }}
                      // className="wide"
                      onChange={(res) => {
                        // dispatch(setLoading(true))
                        onChange({ invoice: [...payload.invoice, res[0].address ] })
                        setInvoiceData([...invoiceData, { address: res[0].address, name: res[0].name } ])
                        // onChange({ invoice: [...payload.invoice, { address: res[0].address, name: res[0].name } ] })
                        dispatch(setLoading(false))
                      }}
                     />
                     </div>
                  </FileDrop>}

                {(type === 'invoice' && payload?.invoice?.length > 0) && 
                  <div className="row main">
                    {payload?.invoice?.map((data, i) => (
                      <div key={i} className="row image">
                        {/* <p>{(data?.name ?? data).split('/').pop().split('-').slice(1)}</p> */}
                        {/* <p>{data?.name ?? data}</p> */}
                        <p>{invoiceData[i]?.name || data?.name || data}</p>
                        <img
                          alt='uploaded-file'
                          src={require("../../../assets/icons/close-icon.svg").default}
                          className="delete-icon"
                          onClick={() => {
                            let newInvoiceList = payload.invoice.slice();
                            newInvoiceList.splice(i, 1);
                            newInvoiceList = newInvoiceList.map((invoice) => invoice?.name || invoice)

                            onChange({ invoice: newInvoiceList.map(
                                (invoice) => invoice?.name || invoice
                              ),
                            });
                            let newInvoiceData = invoiceData.slice()
                            newInvoiceData.splice(i, 1);
                            setInvoiceData(newInvoiceData)

                            // onChange(newInvoiceList);
                          }}
                        />
                      </div>
                    ))}
                  </div>}
              

                {type === 'manual' && 
                <>
                  <div className='input-wrapper text-icon medium'>
                    <p>{t('offers.inputTitleAverageConsumation')}</p>
                    <div className='text-input-wrapper'>
                      <input
                        value={payload?.power}
                        onChange={(e) => onChange({ averageConsumation: e.target.value })}
                      ></input>
                      <p className='input-text'>kW</p>
                    </div>
                  </div>

                  <div className='input-wrapper wide'>
                    <p>{t('offers.inputTitleComment')}</p>
                    <input
                      value={payload?.comment}
                      onChange={(e) => onChange({ comment: e.target.value })}
                    ></input>
                   </div>
                </>} 
            </div>
        </div>

        {type === 'invoice' &&
        <div className='section-wrapper'>
          <div className='section-title'>
               <div className='flex start col'>
                 <h2>{t('offers.additionalData')}</h2>
               </div>
          </div>

          <div className='section-content'>
           <div className='input-wrapper wide'>
            <p>{t('offers.inputTitleComment')}</p>
            <input
              value={payload?.comment}
              onChange={(e) => onChange({ comment: e.target.value })}
            ></input>
            </div>
          </div>
        </div>}

        <div className='section-wrapper'>
            <div className='section-content no-margin'>
                <div className='flex'>
                    <div className='input-wrapper radio'>
                      <RadioButton
                          name="policy-agreement"
                          id="policy-agreement"
                          value="policy-agreement"
                          type="checkbox"
                          text={t('offers.policyAgreement')}
                          onChange={(e) => {
                            onChange({ policyAgreement: e.target.checked })
                          }}
                        //   onChange={(e) => onChange({ policyAgreement: !payload?.policyAgreement })}
                          checked={payload?.policyAgreement}
                          shape='square'
                          />
                    </div>

                    <button className='primary-button' disabled={isDisabled()} onClick={() => dispatch(sendInquiry({ data: { ...payload, emailType: 'consumer' }, onSuccess: () => { dispatch(setModal(true))}}))}>{t('general.getOffer')}</button>
                </div>
            </div>
        </div>

        {modal &&
        <OfferQueryModal
          onClick={() => {
          dispatch(setModal(false))
          navigate('/')
          }}
         />}

         {loading && <LoadingModal />}

      {/* <Sheet isOpen={open} onClose={() => setOpen(false)} snapPoints={[0.85]}>
        <Sheet.Container>
          <Sheet.Header />
          <Sheet.Content>
          <OfferQueryModal
          // onClick={() => {
          // dispatch(setModal(false))
          // navigate('/')
          // }}
         />
          </Sheet.Content>
        </Sheet.Container>

        <Sheet.Backdrop />
      </Sheet> */}
    </div>
  )
}
