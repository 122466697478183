import { useEffect, useRef, useLayoutEffect } from 'react'

const useIsomorphicLayoutEffect = typeof window !== 'undefined' ? useLayoutEffect : useEffect

export const useInterval = (callback, delay) => {
    const savedCallback = useRef(callback)
    useIsomorphicLayoutEffect(() => { savedCallback.current = callback }, [callback])

    useEffect(() => {
        if (!delay && delay !== 0) { return }
        const id = setInterval(() => savedCallback.current(), delay)
        return () => clearInterval(id)
    }, [delay])
}