import React from 'react';
import './styles.scss';
import Slider from "react-slick";
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import { useTranslation } from 'react-i18next';

export default function PartnersSection() {
  const { t } = useTranslation()

  const data = [
    { partner: t('partners.unicredit'), post: t('partners.unicreditPost'), logo: 'unicredit.png', name: t('partners.unicreditName'), text: t('partners.unicreditText') },
    { partner: t('partners.monbat'), post: t('partners.monbatPost'), logo: 'monbat.png', name: t('partners.monbatName'), text: t('partners.monbatText') },
    { partner: t('partners.AC'), post: t('partners.ACPost'), logo: 'ac.png', name: t('partners.ACName'), text: t('partners.ACText') },
    { partner: t('partners.grandHotelSofia'), post: t('partners.grandHotelSofiaPost'), logo: 'grand-hotel-sofia.png', name: t('partners.grandHotelSofiaName'), text: t('partners.grandHotelSofiaText') },
  ]
  const settings = {
    dots: false,
    arrows: true,
    infinite: true,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 2,
  };

  return (
    <div className='screen-section-wrapper partners'>
      <div className='section-wrapper text'>
        <div className='title-wrapper'>
          <h1>{t('general.headerPartners')}</h1>
          <div className='flex'>
            <div className='line' />
            <h2>{t('home.partnersSectionSubtitle')}</h2>
          </div>
        </div>
      </div>
      <div className='cards-wrapper desktop'>
        <Slider {...settings}>
          {data?.map((el, index) => <div className='card-wrapper' key={`web-${el.partner}`}>
            <div className='top'>
              {el.logo && <img src={require(`../../../assets/images/${el.logo}`)} alt='brand logo' />}
              <div className='flex'>
                <h4>{el.partner}</h4>
                <h5>{el.post}</h5>
              </div>
            </div>
            <div className='bottom'>
              <h4>{el.name}</h4>
              <p>{el.text}</p>
            </div>
          </div>)}
        </Slider>
      </div>
      <div className='cards-wrapper mobile'>
        {data?.map((el, index) => <div className='card-wrapper' key={`mobile-${el.partner}`}>
          <div className='top'>
            {el.logo && <img src={require(`../../../assets/images/${el.logo}`)} alt='brand logo' />}
            <div className='flex'>
              <h4>{el.partner}</h4>
              <h5>{el.post}</h5>
            </div>
          </div>
          <div className='bottom'>
            <h4>{el.name}</h4>
            <p>{el.text}</p>
          </div>
        </div>)}
      </div>
    </div>
  )
}
