import React from 'react';
import './styles.scss';
import { ProductsSection, OfferBanner } from '../../components';
import { useEffect } from 'react';

export default function Products() {
  
  useEffect(() => {
    window.scrollTo(0, 0, { behavour: 'smooth' })
  }, []);

  return (
    <div className='screen-wrapper products'>
        <div className='screen-inner-wrapper'>
          <ProductsSection />
          <OfferBanner />
        </div>
    </div>
  )
}
