import { createSlice } from "@reduxjs/toolkit"
import { httpClient } from '../utilities/httpClient'
// import {  startLoading, stopLoading } from "actions"

const initialState = {
  usersList: {},
  singleUser: {},
}

export const NewsSlice = createSlice({
  name: "News",
  initialState,
  reducers: {
    createArticleSuccess: (state, { payload }) => {
      state.articleList = payload
    },
    editArticleSuccess: (state, { payload }) => {
      state.article = payload
    },
    deleteArticleSuccess: (state, { payload }) => {
      state.article = payload
    }
  },
})

export const {
  createArticleSuccess,
  editArticleSuccess,
  deleteArticleSuccess
} = NewsSlice.actions

export const getNewsList = ({ payload, onSuccess }) => async () => {
  const response = await httpClient.post("/article/browse", payload)
  if (onSuccess) onSuccess(response?.data)
}

export const getNewsCategories = ({ payload, onSuccess }) => async () => {
  const response = await httpClient.post("/articleCategory/browse", payload)
  if (onSuccess) onSuccess(response?.data?.articleCategories)
}

export const createArticle = ({ data, onSuccess }) => async (dispatch) => {
  const response = await httpClient.post("/article", data)

  dispatch(createArticleSuccess(response?.data?.payload))
  if (onSuccess) onSuccess(response?.data?.articles)
}

export const getArticle = ({ id, onSuccess }) => async (dispatch) => {
  const response = await httpClient.get(`/article/${id}`)
  if (onSuccess) onSuccess(response?.data?.article)
}

export const editArticle = ({ data, onSuccess }) => async (dispatch) => {
  const response = await httpClient.put(`/article`, data)

  dispatch(editArticleSuccess(response))
  if (onSuccess) onSuccess(response?.data?.article)

}

export const deleteArticle = ({ data, onSuccess }) => async (dispatch) => {
  const response = await httpClient.put(`/article`, data)

  dispatch(editArticleSuccess(response))
  if (onSuccess) onSuccess(response?.data?.article)

}