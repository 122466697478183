import React from 'react';
import './styles.scss';
import RadioButton from '../../RadioButton/RadioButton';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { OfferQueryModal } from '../../Modals';
import { setModal } from '../../../actions/general';
import { sendInquiry } from '../../../actions/inquiries';
import { useTranslation } from 'react-i18next';

export default function OfferOtherEnergy({ payload, onChange }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const modal = useSelector(state => state.general.modal);
  const { t } = useTranslation();
  let isDisabled = !(payload?.fullName && payload?.email && payload?.phoneNumber && payload?.firmName && payload?.type && payload?.policyAgreement);

  return (
    <div className='offer-inputs-wrapper manufacturers-energy'>
        <div className='section-wrapper'>
            <div className='section-title'>
               <div className='flex start col'>
                 <h2>{t('offers.inputTitleContactData')}</h2>
               </div>
            </div>

            <div className='section-content'>
                <div className='input-wrapper'>
                  <p>{t('offers.inputTitleFullName')} <span className="orange">*</span></p>
                  <input
                    value={payload?.fullName}
                    onChange={(e) => onChange({ fullName: e.target.value })}
                  ></input>
                </div>
                
                <div className='input-wrapper'>
                  <p>{t('offers.inputTitleEmail')} <span className="orange">*</span></p>
                  <input
                    value={payload?.email}
                    onChange={(e) => onChange({ email: e.target.value })}
                  ></input>
                </div>

                <div className='input-wrapper'>
                  <p>{t('general.phoneNumber')} <span className="orange">*</span></p>
                  <input
                    value={payload?.phoneNumber}
                    onChange={(e) => onChange({ phoneNumber: e.target.value })}
                  ></input>
                </div>
                
                <div className='input-wrapper last'>
                  <p>{t('offers.inputTitleFirmName')} <span className="orange">*</span></p>
                  <input
                    value={payload?.firmName}
                    onChange={(e) => onChange({ firmName: e.target.value })}
                  ></input>
                </div>

            </div>
        </div>

        <div className='section-wrapper'>
            <div className='section-title'>
               <div className='flex start col'>
                 <h2>{t('offers.productionData')}</h2>
               </div>
            </div>

            <div className='section-content start'>
              <div className='input-wrapper radio wide'>
                <p>{t('offers.type')} <span className="orange">*</span></p>

                  <div className='flex start'>
                     <RadioButton
                      name="TPP"
                      id="TPP"
                      value="TPP"
                      text={t('offers.TPP')}
                      onChange={(e) => onChange({ type: 'ТЕЦ' })}
                      checked={payload?.type === 'ТЕЦ'}
                      />
   
                     <RadioButton
                      name="bio-TPP"
                      id="bio-TPP"
                      value="bio-TPP"
                      text={t('offers.bioHPP')}
                      onChange={(e) => onChange({ type: 'Био ГЕЦ' })}
                      checked={payload?.type === 'Био ГЕЦ'}
                      />
   
                     <RadioButton
                      name="gas-plant"
                      id="gas-plant"
                      value="gas-plant"
                      text={t('offers.gasPlant')}
                      onChange={(e) => onChange({ type: 'Газова централа' })}
                      checked={payload?.type === 'Газова централа'}
                      />
   
                     <RadioButton
                      name="cogeneration"
                      id="cogeneration"
                      value="cogeneration"
                      text={t('offers.cogeneration')}
                      onChange={(e) => onChange({ type: 'Когенерация' })}
                      checked={payload?.type === 'Когенерация'}
                      />
   
                     <RadioButton
                      name="electrical-energy-storage-facility"
                      id="electrical-energy-storage-facility"
                      value="electrical-energy-storage-facility"
                      text={t('offers.electricalEnergyStorageFacility')}
                      onChange={(e) => onChange({ type: 'Съоръжение за съхранение на електрическа енергия' })}
                      checked={payload?.type === 'Съоръжение за съхранение на електрическа енергия'}
                      />
   
                     <RadioButton
                      name="other"
                      id="other"
                      value="other"
                      text={t('offers.other')}
                      onChange={(e) => onChange({ type: 'Друго' })}
                      checked={payload?.type === 'Друго'}
                      />
                  </div>
                </div>

                <div className={`input-wrapper text-icon ${window.innerWidth > 1000 && "small"}`}>
                  <p>{t('offers.power')}</p>

                  <div className='text-input-wrapper'>
                    <input
                      value={payload?.power}
                      onChange={(e) => onChange({ power: e.target.value })}
                    ></input>

                    <p className='input-text'>kW</p>
                  </div>
                </div>

                <div className={`input-wrapper ${window.innerWidth > 1000 && "small"}`}>
                  <p>{t('offers.inputTitleLocation')}</p>
                  <input
                      value={payload?.location}
                      onChange={(e) => onChange({ location: e.target.value })}
                    ></input>
                </div>

                <div className='input-wrapper wide'>
                  <p>{t('offers.inputTitleComment')}</p>
                  <input
                    value={payload?.comment}
                    onChange={(e) => onChange({ comment: e.target.value })}
                  ></input>
                </div>
                
            </div>
        </div>

        <div className='section-wrapper'>
            <div className='section-content no-margin'>
                <div className='flex'>
                    <div className='input-wrapper radio'>
                      <RadioButton
                          name="policy-agreement"
                          id="policy-agreement"
                          value="policy-agreement"
                          type="checkbox"
                          text={t('offers.policyAgreement')}
                          onChange={(e) => {
                            onChange({ policyAgreement: e.target.checked })
                          }}
                        //   onChange={(e) => onChange({ policyAgreement: !payload?.policyAgreement })}
                          checked={payload?.policyAgreement}
                          shape='square'
                          />
                    </div>

                    <button className='primary-button' disabled={isDisabled} onClick={() => dispatch(sendInquiry({ data: { ...payload, emailType: 'producer' }, onSuccess: () => { dispatch(setModal(true)) } }))}>{t('general.getOffer')}</button>
                </div>
            </div>
        </div>
        {modal &&
          <OfferQueryModal
            onClick={() => {
              dispatch(setModal(false))
              navigate('/')
            }}
          />}
    </div>
  )
}
