import React from 'react';
import './styles.scss';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { changeTab } from '../../../actions/general';
import { useTranslation } from 'react-i18next';

export default function AboutSection() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { t } = useTranslation();

  return (
    <div className='screen-section-wrapper about'>
        <div className='section-wrapper left'>
           <img src={require('../../../assets/images/about-section-map.png')} alt='map' />
        </div>

        <div className='section-wrapper right'>
            <div className='title-wrapper'>
              <h1>{t('general.headerAbout')}</h1>
              <div className='flex'>
                <div className='line' />
                <h2>{t('home.aboutSectionSubtitle')}</h2>
              </div>
            </div>

            <div className='text-wrapper'>
                <p>{t('home.aboutSectionTextFirst')}</p>
                <p>{t('home.aboutSectionTextSecond')}</p>
            </div>

            <button className='primary-button' onClick={() => {
              dispatch(changeTab('about'))
              navigate('/about')
              }}>{t('general.learnMore')}</button>
        </div>
  </div>
  )
}
