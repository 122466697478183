import React, { useState, useEffect } from 'react';
import './styles.scss';
import { OffersTopSection, OfferEnergy, OfferOtherEnergy } from '../../../components';
import { setEnergyType } from '../../../actions/general';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';

export default function OfferManufacturer() {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const selectedType = useSelector(state => state.general.energyType);
  const [ payload, setPayload ] = useState({
    fullName: '',
    email: '',
    phoneNumber: '',
    firmName: '',
    workingHeadquarters: '',
    startDate: '',
    plannedStartDate: '',
    power: '',
    location: '',
    preferentialPrice: '',
    accessionAgreement: '',
    comment: '',
    policyAgreement: false
  })

  const [ payloadOtherEnergy, setPayloadOtherEnergy ] = useState({
    fullName: '',
    email: '',
    phoneNumber: '',
    firmName: '',
    type: '',
    power: '',
    location: '',
    comment: '',
    policyAgreement: false
  })

  // const [ payload, setPayload ] = useState({
  //   fullName: '',
  //   email: '',
  //   phoneNumber: '',
  //   firmName: '',
  //   workingHeadquarters: ''
  // })

  const handleChange = (newPayload) => setPayload((payload) => ({ ...payload, ...newPayload })); 

  useEffect(() => {
    window.scrollTo(0, 0, { behavour: 'smooth' })
  }, []);

  return (
    <div className='screen-wrapper offer'>
      <div className='screen-inner-wrapper'>
        <OffersTopSection />

        <div className='form-wrapper'>
          <div className='section-wrapper'>
            <div className='section-title'>
               <div className='flex start col'>
                 <h2>{t('offers.productionType')}</h2>
                 <p>{t('offers.productionTypeSubtitle')}</p>
               </div>
            </div>

            <div className='section-content'>
                <div className={`card ${selectedType === 'solar energy' ? 'selected' : ''}`} onClick={() => dispatch(setEnergyType('solar energy'))}>
                  <h2>{t('offers.solarEnergyType')}</h2>
                  <img src={require('../../../assets/icons/sun.svg').default} className='icon' alt='sun' />
                </div>

                <div className={`card ${selectedType === 'hydro energy' ? 'selected' : ''}`} onClick={() => dispatch(setEnergyType('hydro energy'))}>
                  <h2>{t('offers.hydroEnergyType')}</h2>
                  <img src={require('../../../assets/icons/water-drop.svg').default} className='icon' alt='water' />
                </div>

                <div className={`card ${selectedType === 'wind energy' ? 'selected' : ''}`} onClick={() => dispatch(setEnergyType('wind energy'))}>
                  <h2>{t('offers.windEnergyType')}</h2>
                  <img src={require('../../../assets/icons/wind.svg').default} className='icon' alt='wind' />
                </div>

                <div className={`card ${selectedType === 'other energy' ? 'selected' : ''}`} onClick={() => dispatch(setEnergyType('other energy'))}>
                  <h2>{t('offers.otherEnergyType')}</h2>
                  <img src={require('../../../assets/icons/menu.svg').default} className='icon' alt='menu' />
                </div>
            </div>
          </div>

          {selectedType === 'solar energy' && <OfferEnergy payload={payload} onChange={handleChange} type='solar energy' />}
          {selectedType === 'hydro energy' && <OfferEnergy payload={payload} onChange={handleChange} type='hydro energy' />}
          {selectedType === 'wind energy' && <OfferEnergy payload={payload} onChange={handleChange} type='wind energy' />}
          {selectedType === 'other energy' && <OfferOtherEnergy payload={payloadOtherEnergy} onChange={(newPayload) => setPayloadOtherEnergy((payloadOtherEnergy) => ({ ...payloadOtherEnergy, ...newPayload }))} />}
        </div>
      </div>
    </div>
  )
}
