import React, { useState, useEffect, useCallback } from "react";
import "./styles.scss";
import { OfferBanner } from "../../components";
import { changeTab } from "../../actions/general";
import { getContacts } from "../../actions/contact";
import { useSelector, useDispatch } from "react-redux";
import { mapAttributes } from "../../config/map";
import { useTranslation } from "react-i18next";
import { Helmet } from "react-helmet";

export default function Contact() {
  const dispatch = useDispatch();
  const [docs, setDocs] = useState([]);
  const [selected, setSelected] = useState([]);
  const language = useSelector(state => state.general.language);
  const { t } = useTranslation();

  useEffect(() => {
    window.scrollTo(0, 0, { behavour: "smooth" });
    dispatch(changeTab("contact"));
  }, [dispatch]);

  const fetchData = useCallback(
    (payload) => {
      dispatch(getContacts({ payload }));
    },
    [dispatch]
  );

  useEffect(() => {
    fetchData({
      onSuccess: (res) => {
        setDocs(res);
      },
    });
  }, [fetchData]);

  return (
    <div className="screen-wrapper contact">
      <Helmet>
        <title>Synergon Energy Contact</title>
        <meta name="robots" content="index, follow"></meta>
      </Helmet>
      <div className="screen-inner-wrapper">
        <div className="main-section-wrapper map desktop">
          <p className="card-non-selected">{t('contacts.mapText')}</p>
          {/* <img className='map' src={require('../../assets/images/bg.svg')} /> */}
          {/* <div className="map" /> */}
          <div className="svg-container">
            <svg xmlns="http://www.w3.org/2000/svg" preserveAspectRatio="xMidYMin" viewBox={"0 0 1000 1000"}>
              {mapAttributes?.map((atr) => {
                return (
                  <React.Fragment key={atr.props.id}>
                    <path
                      {...atr.props}
                      stroke={atr.name === "София" || atr.name === "София (област)" ? "#f75d25" : "black"}
                      style={{ cursor: "pointer", fill: selected.includes(atr.name) ? "#f75d25" : "white" }}
                      // onClick={() => (selected.includes(atr.name) ? setSelected(selected.filter((sel) => sel !== atr.name)) : setSelected([...selected, atr.name]))}
                      onClick={() => (selected.includes(atr.name) ? setSelected([]) : setSelected([atr.name]))}
                    ></path>
                  </React.Fragment>
                );
              })}
              <circle cx="87.8" cy="497.5" id="0"></circle>
              <circle cx="88.5" cy="498" id="1"></circle>
              <circle cx="91.6" cy="496.5" id="2"></circle>
            </svg>
          </div>
        </div>

        <div className="main-section-wrapper text">
          <div className="section-wrapper">
            <div className="title-wrapper">
              <h1>{t('general.headerContact')}</h1>
              <div className="flex">
                <div className="line" />
                <h2>{t('contacts.contactUs')}</h2>
              </div>
            </div>

            <div className="text-wrapper">
              <div className="flex rows">
                <div className="row">
                  {t('contacts.contactsPhoneNumber')} <span>0700 800 80</span><span style={{ whiteSpace: 'pre-wrap' }}>{`\n`}{t('contacts.contactsPhoneNumberWorkingHours')}</span>
                </div>
                <div className="row">
                  {t('contacts.contactsEmail')} <span>office@synergonenergy.bg</span>
                </div>
                <div className="row">
                  {t('contacts.contactsAddress')} <span>{t('general.synergonEnergyAddress')}</span>
                </div>
              </div>
            </div>

            {!selected?.length ?
              <div className="scroll-wrapper desktop">
                <p className="card-non-selected margin">{t('contacts.mapText')}</p>
              </div>
              :
              <div className="scroll-wrapper desktop">
                {docs
                  ?.filter((el) => !selected.length || selected.includes(el.region))
                  ?.map((el, i) => {
                    return (
                      <div className="region-info-wrapper" key={`web-${el._id}`}>
                        {i === 0 &&
                          <h2>{(language === 'bg' ? el?.region : el?.regionEnglish) || ''}</h2>}
                        <div className="row-card-wrapper" key={i}>
                          {el.picture && <div className="person-image" style={{ backgroundImage: `url(${el.picture})`, }} />}
                          <h3>{(language === 'bg' ? el?.name : el?.nameEnglish) || ''}</h3>
                          <p>{(language === 'bg' ? el?.jobTitle : el?.jobTitleEnglish) || ''}</p>

                          <div className="row-icon-wrapper">
                            <img src={require("../.././assets/icons/phone-orange.svg").default} className="icon" alt='phone' />
                            <p>{el?.phoneNumbers?.map((phone, i) => (i === el?.phoneNumbers?.length - 1 ? `+${phone}` : "+" + phone + ","))}</p>
                          </div>

                          <div className="row-icon-wrapper">
                            <img src={require("../.././assets/icons/email-orange.svg").default} className="icon" alt='email' />
                            <p>{el?.emails?.map((email, i) => (i === el?.emails?.length - 1 ? email : email + ","))}</p>
                          </div>
                        </div>
                      </div>
                    );
                  })}
              </div>}

            <div className="scroll-wrapper mobile">
              {docs
                ?.filter((el) => !selected.length || selected.includes(el.region))
                ?.sort(function (a, b) {
                  if (a.region < b.region) return -1;
                  if (a.region > b.region) return 1;
                  return 0;
                })
                ?.map((el, i, arr) => {
                  return (
                    <div className="region-info-wrapper" key={`mobile-${el._id}`}>
                      {el?.region !== arr?.[i - 1]?.region &&
                        <h2>{(language === 'bg' ? el?.region : el?.regionEnglish) || ''}</h2>}
                      <div className="row-card-wrapper" key={i}>
                        {el.picture && <div className="person-image" style={{ backgroundImage: `url(${el.picture})`, }} />}
                        <h3>{(language === 'bg' ? el?.name : el?.nameEnglish) || ''}</h3>
                        <p>{(language === 'bg' ? el?.jobTitle : el?.jobTitleEnglish) || ''}</p>

                        <div className="row-icon-wrapper">
                          <img src={require("../.././assets/icons/phone-orange.svg").default} className="icon" alt='phone' />
                          <p>{el?.phoneNumbers?.map((phone, i) => (i === el?.phoneNumbers?.length - 1 ? `+${phone}` : "+" + phone + ","))}</p>
                        </div>

                        <div className="row-icon-wrapper">
                          <img src={require("../.././assets/icons/email-orange.svg").default} className="icon" alt='email' />
                          <p>{el?.emails?.map((email, i) => (i === el?.emails?.length - 1 ? email : email + ","))}</p>
                        </div>
                      </div>
                    </div>
                  );
                })}
            </div>
          </div>
        </div>
      </div>

      <OfferBanner />
    </div>
  );
}
