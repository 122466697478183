import React from 'react';
import './styles.scss';
import { useNavigate } from 'react-router-dom';
import { OfferBanner } from '../../components';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { changeTab } from '../../actions/general';
import { useTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet';

export default function Manufacturers() {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { t } = useTranslation();

    useEffect(() => {
      window.scrollTo(0, 0, { behavour: 'smooth' })
      dispatch(changeTab('products-and-services'))
    }, [dispatch]);

  return (
    <div className='screen-wrapper manufacturers'>
      <Helmet>
          <title>Synergon Energy Manufacturers</title>
          <meta name="robots" content="index, follow"></meta>
      </Helmet>
      <div className='screen-inner-wrapper'>
        <div className='screen-header-wrapper'>
            <div className='flex padding' onClick={() => navigate(-1)}>
                <img src={require('../../assets/icons/arrow.svg').default} alt='arrow back' /> 
                <span>{t('general.back')}</span>
            </div>

            <div className='section-wrapper text'>
                <div className='title-wrapper'>
                  <h2 className="title-section">{t('productsAndServices.manufacturers')}</h2>
                  <div className='flex'>
                    <div className='line orange' />
                    <h2>{t('manufacturers.manufacturersSubtitle')}</h2>
                  </div>
                </div>
           </div>
        </div>

        <div className='screen-main-wrapper'>
          <div className='cards-wrapper'>
              <div className='section-wrapper card'>
                   <img className='icon' src={require('../../assets/icons/handover.svg').default} alt='handover' />
                   <p>{t('manufacturers.cardOneText')}</p>
               </div>
    
               <div className='section-wrapper card'>
                   <img className='icon' src={require('../../assets/icons/card.svg').default} alt='card' />
                   <p>{t('manufacturers.cardTwoText')}</p>
               </div>
    
               <div className='section-wrapper card'>
                   <img className='icon' src={require('../../assets/icons/laptop.svg').default} alt='laptop' />
                   <p>{t('manufacturers.cardThreeText')}</p>
               </div>
    
                <div className='section-wrapper card'>
                   <img className='icon' src={require('../../assets/icons/settings.svg').default} alt='settings' />
                   <p>{t('manufacturers.cardFourText')}</p>
               </div>
    
                <div className='section-wrapper card'>
                   <img className='icon' src={require('../../assets/icons/consultation.svg').default} alt='consultation' />
                   <p>{t('manufacturers.cardFiveText')}</p>
               </div>
          </div>

          <div className='section-wrapper bottom'>
            <div className='title-wrapper'>
              <h2 className="title-section">{t('manufacturers.experienceTitle')}</h2>
              <div className='flex'>
                <div className='line' />
                <h2>{t('manufacturers.experienceSubtitle')}</h2>
              </div>
            </div>

            <div className='cards-wrapper'>
              <div className='section-wrapper bottom-card'>
                   <img className='card-image' src={require('../../assets/images/manufacturers-1.png')} alt='manufacturers' />
                   <div className='card-text-wrapper'>
                     <h2>{t('manufacturers.energyCardOneTitle')}</h2>
                     <div className='card-content-wrapper'>
                       <div className='flex'>
                         <div className='line orange' />
                         <p>{t('manufacturers.energyCardOneTextOne')}</p>
                       </div>
  
                       <div className='flex'>
                         <div className='line orange' />
                         <p>{t('manufacturers.energyCardOneTextTwo')}</p>
                       </div>
                     </div>
                   </div>
               </div>

              <div className='section-wrapper bottom-card'>
                   <img className='card-image' src={require('../../assets/images/manufacturers-2.png')} alt='manufacturers' />
                   <div className='card-text-wrapper'>
                     <h2>{t('manufacturers.energyCardTwoTitle')}</h2>
                     <div className='card-content-wrapper'>
                        <div className='flex'>
                          <div className='line orange' />
                          <p>{t('manufacturers.energyCardTwoTextOne')}</p>
                        </div>
   
                        <div className='flex'>
                          <div className='line orange' />
                          <p>{t('manufacturers.energyCardTwoTextTwo')}</p>
                        </div>
                     </div>
                   </div>
               </div>

              <div className='section-wrapper bottom-card'>
                   <img className='card-image' src={require('../../assets/images/manufacturers-3.png')} alt='manufacturers' />
                   <div className='card-text-wrapper'>
                     <h2>{t('manufacturers.energyCardThreeTitle')}</h2>
                     <div className='card-content-wrapper'>
                        <div className='flex'>
                          <div className='line orange' />
                          <p>{t('manufacturers.energyCardThreeTextOne')}</p>
                        </div>
   
                        <div className='flex'>
                          <div className='line orange' />
                          <p>{t('manufacturers.energyCardThreeTextTwo')}</p>
                        </div>
   
                        <div className='flex'>
                          <div className='line orange' />
                          <p>{t('manufacturers.energyCardThreeTextThree')}</p>
                        </div>
                     </div>
                   </div>
               </div>

              <div className='section-wrapper bottom-card'>
                   <img className='card-image' src={require('../../assets/images/manufacturers-4.png')} alt='manufacturers' />
                   <div className='card-text-wrapper'>
                     <h2>{t('manufacturers.energyCardFourTitle')}</h2>
                     <div className='card-content-wrapper'>
                        <div className='flex'>
                          <div className='line orange' />
                          <p>{t('manufacturers.energyCardFourTextOne')}</p>
                        </div>
   
                        <div className='flex'>
                          <div className='line orange' />
                          <p>{t('manufacturers.energyCardFourTextTwo')}</p>
                        </div>
   
                        <div className='flex'>
                          <div className='line orange' />
                          <p>{t('manufacturers.energyCardFourTextThree')}</p>
                        </div>
                     </div>
                   </div>
               </div>

            </div>
          </div>
        </div>

        <OfferBanner />
      </div>
    </div>
  )
}
