import React from 'react';
import './styles.scss';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { setOfferType } from '../../../actions/general';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

export default function OffersInitial() {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { t } = useTranslation();

    useEffect(() => {
      window.scrollTo(0, 0, { behavour: 'smooth' })
    }, []);

  return (
    <div className='screen-wrapper offers-initial'>
      <div className='screen-inner-wrapper'>
      <div className="main-section top">
          <div className="section-wrapper">
            <div className="title-wrapper">
              <h1>{t('general.getOffer')}</h1>
              <div className="flex">
                <div className="line" />
                <h2>{t('offers.offersInitialSubtitle')}</h2>
              </div>
            </div>
          </div>
        </div>

      <div className="main-section center">
          <div className="section-wrapper card" 
          // onClick={() => navigate('/offers-options', { state: { offerType: 'users' } })}
          onClick={() => {
            dispatch(setOfferType('users'))
            navigate('/offers-options')
          }}>
            <h2>{t('productsAndServices.users')}</h2>
            <img className='icon' src={require('../../../assets/icons/electricity.svg').default} alt='electricity' />
          </div>

          <div className='section-wrapper'>
            <p>{t('offers.or')}</p>
          </div>

          <div className="section-wrapper card" 
          // onClick={() => navigate('/offers-options', { state: { offerType: 'manufacturers' } })}
          onClick={() => {
            dispatch(setOfferType('manufacturers'))
            navigate('/offers-options')
          }}>
            <h2>{t('about.aboutGraphicManufacturers')}</h2>
            <img className='icon' src={require('../../../assets/icons/solar-panels.svg').default} alt='solar panels' />
          </div>
        </div>

        <p className='desktop'>{t('offers.offersInitialTextOne')}<span className='link' onClick={() => navigate('/contact')}>{t('offers.offersInitialTextTwo')}</span></p>
        <p className='mobile margin'>{t('offers.offersInitialTextOneMobile')}</p>
        <p className='mobile'>{t('offers.please')}<span className='link' onClick={() => navigate('/contact')}>{t('offers.offersInitialTextTwo')}</span></p>
      </div>
    </div>
  )
}
