import React from 'react';
import './styles.scss';
import { InfoSection, OfferBanner } from '../../components';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { changeTab } from '../../actions/general';
import { setLoading } from '../../actions/general';
import { Helmet } from 'react-helmet';

export default function Info() {
  const dispatch = useDispatch();
  
  useEffect(() => {
    window.scrollTo(0, 0, { behavour: 'smooth' })
    dispatch(changeTab('info'))
    dispatch(setLoading(true))
  }, [dispatch]);

  return (
    <div className='screen-wrapper info'>
      <Helmet>
          <title>Synergon Energy Articles and Useful Information</title>
          <meta name="robots" content="index, follow"></meta>
      </Helmet>
      <div className='screen-inner-wrapper'>
        <InfoSection />
        <OfferBanner />
      </div>
    </div>
  )
}
