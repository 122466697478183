import React from 'react';
import './styles.scss';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

export default function HeroSection() {
  const navigate = useNavigate();
  const { t } = useTranslation();
  
  return (
    <div className='screen-section-wrapper hero'>
        <div className='text-section-wrapper'>
          <div className='mobile'>
             <img src={require('../../../assets/images/hero-section-background.png')} alt='background' />
           </div>

           <div className='card-wrapper'>
             <h1>{t('home.heroSectionTitile')}</h1>
             <p>{t('home.heroSectionSubtitle')}</p>
     
             <div className='buttons-wrapper'>
                 <button className='primary-button'  onClick={() => navigate('/offers-initial')}>{t('general.getOffer')}</button>
                 <button className='secondary-button' onClick={() => window.open('https://www.youtube.com/watch?v=VyoJ_deI4KM')}>
                    {t('general.video')}
                     <img src={require('../../../assets/icons/video-camera.svg').default} alt='video camera' />
                 </button>
             </div>
           </div>
        </div>
  </div>
  )
}
