import React from 'react';
import './styles.scss';
import Slider from "react-slick";
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { PartnersSection } from '../../components';
import { OfferBanner } from '../../components';
import { useEffect } from 'react';
import { useTranslation } from "react-i18next";

export default function Partners() {
  const { t } = useTranslation();
  const data = [
    { logo: 'partners-brand-1.png' },
    { logo: 'partners-brand-2.png' },
    { logo: 'partners-brand-3.png' },
    { logo: 'partners-brand-4.png' },
    { logo: 'partners-brand-5.png' },
    { logo: 'partners-brand-6.png' },
    { logo: 'partners-brand-7.png' },
    { logo: 'partners-brand-8.png' },
    { logo: 'partners-brand-9.png' },
    { logo: 'partners-brand-10.png' },
    { logo: 'partners-brand-11.png' },
    { logo: 'partners-brand-12.png' }
  ]

  const dataVideos = [
    { name: t('partners.videoCardOneTitle'), post: t('partners.videoCardOneText'), video: 'https://www.youtube.com/embed/E-pjG60BHfY' },
    { name: t('partners.videoCardTwoTitle'), post: t('partners.videoCardTwoText'), video: 'https://www.youtube.com/embed/KxmQSBlXU2U' },
    { name: t('partners.videoCardThreeTitle'), post: t('partners.videoCardThreeText'), video: 'https://www.youtube.com/embed/u_ZxDU_Due0' },
    { name: t('partners.videoCardFourTitle'), post: t('partners.videoCardFourText'), video: 'https://www.youtube.com/embed/tI4bxCvXZMM' },
    { name: t('partners.videoCardFiveTitle'), post: t('partners.videoCardFiveText'), video: 'https://www.youtube.com/embed/EzSE1NubEws' }
  ]

  const settings = {
    dots: false,
    arrows: true,
    infinite: true,
    speed: 500,
    slidesToShow: 6,
    slidesToScroll: 2,
  };

  const settingsVideo = {
    dots: false,
    arrows: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 2,
  };

  useEffect(() => {
    window.scrollTo(0, 0, { behavour: 'smooth' })
  }, []);

  return (
    <div className='screen-wrapper partners'>
      <div className='screen-inner-wrapper'>
        <div className="main-section top">
          <PartnersSection />
        </div>

        <div className="main-section activities">
          <div className="title-wrapper">
            <h1>{t('partners.businessTitle')}</h1>
            <div className="flex">
              <div className="line" />
              <h2>{t('partners.businessSubtitle')}</h2>
            </div>

            <p className='text-wrapper'>
              {t('partners.businessTextOne')}
            </p>
            <p className='text-wrapper'>{t('partners.businessTextTwo')}</p>
          </div>

          <div className="cards-wrapper">
            <div className='section-wrapper card'>
              <img className='icon' src={require('../../assets/icons/bribe.svg').default} alt='business' />
              <p>{t('partners.businessCardOne')}</p>
            </div>

            <div className='section-wrapper card'>
              <img className='icon' src={require('../../assets/icons/document.svg').default} alt='document' />
              <p>{t('partners.businessCardTwo')}</p>
            </div>

            <div className='section-wrapper card'>
              <img className='icon' src={require('../../assets/icons/contacts.svg').default} alt='contacts' />
              <p>{t('partners.businessCardThree')}</p>
            </div>

            <div className='section-wrapper card'>
              <img className='icon' src={require('../../assets/icons/network.svg').default} alt='network' />
              <p>{t('partners.businessCardFour')}</p>
            </div>
          </div>

          <div className="brands-wrapper">
            <div className="title-wrapper">
              <h2 className="title-section">{t('partners.partnersTitle')}</h2>
              <div className="flex">
                <div className="line" />
                <h2>{t('partners.partnersSubtitleOne')}</h2>
              </div>
            </div>
            <div className='cards-wrapper slider slider-all desktop videos'>
              <Slider {...settingsVideo}>
                {dataVideos?.map(el => <div className='section-wrapper video-card' key={`web-${el.name}`}>
                  <iframe className='card-image' width="560" height="315" src={el?.video} title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture;" allowFullScreen></iframe>

                  <div className='card-text-wrapper center'>
                    <h2>{el?.name}</h2>
                    <p>{el?.post}</p>
                  </div>
                </div>)}
              </Slider>
            </div>
            <div className='cards-wrapper slider mobile'>
              {dataVideos?.map(el => <div className='section-wrapper video-card' key={`mobile-${el.name}`}>
                <iframe className='card-image' width="560" height="315" src={el?.video} title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture;" allowFullScreen></iframe>
                <div className='card-text-wrapper center'>
                  <h2>{el?.name}</h2>
                  <p>{el?.post}</p>
                </div>
              </div>)}
            </div>
            <div className="title-wrapper slider">
              <div className="flex">
                <div className="line" />
                <h2>{t('partners.partnersSubtitleTwo')}</h2>
              </div>
            </div>

            <div className='cards-wrapper slider slider-all desktop'>
              <Slider {...settings}>
                {data?.map((el, index) => <div className='card-wrapper' key={`web-${el.logo}-${index}`}>
                  {el.logo && <img src={require(`../../assets/images/partners-brand-${index + 1}.png`)} alt='brand' />}
                </div>)}
              </Slider>
            </div>

            <div className='cards-wrapper slider mobile'>
              {data?.map((el, index) => <div className='card-wrapper' key={`mobile-${el.logo}-${index}`}>
                {el.logo && <img src={require(`../../assets/images/partners-brand-${index + 1}.png`)} alt='brand' />}
              </div>)}
            </div>

          </div>
        </div>

        <div className="main-section services">
          <div className="section-wrapper left">
            <div className="title-wrapper">
              <h2 className="title-section">{t('partners.servicesTitle')}</h2>
              <div className="flex">
                <div className="line" />
                <h2>{t('partners.servicesSubtitle')}</h2>
              </div>
            </div>

            <div className="text-wrapper">
              <p>{t('partners.servicesText')}</p>
              <div className="flex small">
                <div className="line" />
                <p>{t('partners.servicesPointOne')}</p>
              </div>
              <div className="flex small">
                <div className="line" />
                <p>{t('partners.servicesPointTwo')}</p>
              </div>
              <div className="flex small">
                <div className="line" />
                <p>{t('partners.servicesPointThree')}</p>
              </div>
              <div className="flex small">
                <div className="line" />
                <p>{t('partners.servicesPointFour')}</p>
              </div>
            </div>
          </div>

          <div className="section-wrapper right">
            <div className="graphic-wrapper">
              <img src={require('../../assets/images/partners-services.png')} alt='services' />
            </div>
          </div>
        </div>

        <OfferBanner />
      </div>
    </div>
  )
}
